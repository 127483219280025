import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { MEMBERSHIP_TYPES, membershipSuccessAction } from '../actions';
import * as Api from '../../../api';
import { getProductsByCategoryActionSuccess } from '../../ProductsByCategory/actions';
import { addComboItemToCartActionSuccess, addItemToCartActionFailure } from 'store/Cart/actions';

export function* membershipWorker({ payload }: any) {
  const { hasMembership, product } = payload;
  try {
    const {
      customerStore,
      productsByCategoryStore,
      customerGroup: { members },
    } = yield select(store => store);

    const customers = [];
    const productIds = [];
    const productsByCategory = [...productsByCategoryStore.productsByCategory];
    const membershipExists = [];

    if (product.isSchedulable) {
      for (let i = 0; i < hasMembership.length; i++) {
        //member info
        let member = members.find(customer => customer.custId === hasMembership[i].customerId);

        // if member does not exist then fetch it
        if (!member) {
          const { data } = yield call(Api.fetchCustomerById, hasMembership[i].customerId);
          member = data;
        }

        // set customer and product ids
        hasMembership[i].membershipProducts.forEach((product: any) => {
          if (productIds.indexOf(product.membershipProductId) === -1) {
            productIds.push(product.membershipProductId);
          }
        });
        customers[i] = { ...member };
      }
    } else {
      customers[0] = { ...customerStore };
      for (let i = 0; i < hasMembership.length; i++) {
        hasMembership[i].membershipProducts.forEach((product: any) => {
          if (productIds.indexOf(product.membershipProductId) === -1) {
            productIds.push(product.membershipProductId);
          }
        });
      }
    }

    productIds.forEach((product: any) => {
      const findProduct = productsByCategory.find(item => item.productId === product);
      if (findProduct) {
        membershipExists.push(findProduct.productId);
      }
    });
    const memberships = productIds.filter(item => membershipExists.indexOf(item) === -1);

    let products;
    if (memberships.length) {
      const { data } = yield call(Api.fetchProductsList, memberships.join(','));

      const productsExists = productsByCategory.filter(product => membershipExists.indexOf(product.productId) !== -1);

      products = [...data, ...productsExists];
      productsByCategory.push(...products.map((item: any) => ({ ...item })));

      yield put<any>(getProductsByCategoryActionSuccess(productsByCategory));
    } else {
      products = productsByCategory.filter(product => productIds.indexOf(product.productId) !== -1);
    }
    yield put<any>(membershipSuccessAction({ customers, product, memberships: products, isLoading: false }));
  } catch (err) {
    console.log({ err });
  }
}

export function* nonSchedulableProductMembershipWorker({ payload }: any) {
  const { hasMembership, product, successProduct } = payload;

  try {
    const customers = [];
    const {
      customerStore,
      productsByCategoryStore,
      customerGroup: { members },
    } = yield select(store => store);

    const productsByCategory = [...productsByCategoryStore.productsByCategory];

    const productIds = [];
    const membershipExists = [];

    for (let i = 0; i < hasMembership.length; i++) {
      // find member data
      let member = members.find(customer => customer.custId === hasMembership[i].customerId);

      // if member data is not in storage fetch it
      if (!member) {
        const { data } = yield call(Api.fetchCustomerById, hasMembership[i].customerId);
        member = data;
      }

      // save productIds and customerIds
      hasMembership[i].membershipProducts.forEach((product: any) => {
        if (productIds.indexOf(product.membershipProductId) === -1) {
          productIds.push(product.membershipProductId);
        }
      });
      customers[i] = { ...member };
    }

    productIds.forEach((product: any) => {
      const findProduct = productsByCategory.find(item => item.productId === product);
      if (findProduct) {
        membershipExists.push(findProduct.productId);
      }
    });
    const memberships = productIds.filter(item => membershipExists.indexOf(item) === -1);

    let products;
    if (memberships.length) {
      const { data } = yield call(Api.fetchProductsList, memberships.join(','));

      const productsExists = productsByCategory.filter(product => membershipExists.indexOf(product.productId) !== -1);

      products = [...data, ...productsExists];

      productsByCategory.push(...products.map((item: any) => ({ ...item })));

      yield put<any>(getProductsByCategoryActionSuccess(productsByCategory));
    } else {
      products = productsByCategory.filter(product => productIds.indexOf(product.productId) !== -1);
    }
    yield put<any>(membershipSuccessAction({ customers, product, memberships: products, isLoading: false }));

    try {
      const cart = yield call<any>(Api.addNonschedulableProductsToCart, successProduct);
      yield put<any>(addComboItemToCartActionSuccess(cart.data.result.value));
    } catch {
      yield put<any>(addItemToCartActionFailure());
    }
  } catch (err) {
    console.log({ err });
  }
}

export default function* membershipSaga() {
  yield all([
    takeLatest(MEMBERSHIP_TYPES.REQUEST, membershipWorker),
    takeLatest(MEMBERSHIP_TYPES.NON_SCHEDULABLE, nonSchedulableProductMembershipWorker),
  ]);
}
