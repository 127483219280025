const KEYBOARD_LAYOUTS = {
  default: [
    '1 2 3 4 5 6 7 8 9 0 - + {bksp}',
    'q w e r t y u i o p [ ] \\',
    "a s d f g h j k l ; ' {enter}",
    '{shift} z x c v b n m , . /',
    '{!123} {space}',
  ],
  defaultShift: [
    '1 2 3 4 5 6 7 8 9 0 - + {bksp}',
    'Q W E R T Y U I O P { } |',
    'A S D F G H J K L : " {enter}',
    '{shift} Z X C V B N M < > ?',
    '{!123} {space}',
  ],
  number: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 .'],
  cardNumber: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
  postCode: [
    '1 2 3 4 5 6 7 8 9 0 - + {bksp}',
    'Q W E R T Y U I O P { } |',
    'A S D F G H J K L : " {enter}',
    'Z X C V B N M < > ?',
    '{!123} {space}',
  ],
  email: [
    '1 2 3 4 5 6 7 8 9 0 - + {bksp}',
    'q w e r t y u i o p [ ] \\',
    "a s d f g h j k l ; ' {enter}",
    '{shift} z x c v b n m , . /',
    '@ @gmail.com @icloud.com .com',
  ],
  emailShift: [
    '~ ! @ # $ % ^ & * ( ) _ = {bksp}',
    'Q W E R T Y U I O P { } |',
    'A S D F G H J K L : " {enter}',
    '{shift} Z X C V B N M < > ?',
    '@gmail.com @icloud.com .com',
  ],
  country: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'q w e r t y u i o p |',
    'a s d f g h j k l {enter}',
    '{shift} z x c v b n m',
    '{space}',
  ],
  countryShift: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    'Q W E R T Y U I O P |',
    'A S D F G H J K L {enter}',
    '{shift} Z X C V B N M',
    '{space}',
  ],
  specialSymbols: [
    '1 2 3 4 5 6 7 8 9 0 {bksp}',
    '@ # $ _ & - + ( ) /',
    `* " ' : ; ! ? { }`,
    '+ = ^ % [ ]',
    '{abc} {space}',
  ],
};

export default KEYBOARD_LAYOUTS;
