import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ReactSignatureCanvas from 'react-signature-canvas';
import DigitalSignatureCanvas from './DigitalSignatureCanvas';
import styles from './styles.module.scss';

const SignatureCanvas = ({ sigPad, signature, enableDigitalSignature, readOnlySignature }: any) => {
  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
  };

  useEffect(() => {
    if (readOnlySignature && !enableDigitalSignature) {
      sigPad.current.off();
    }
  }, [enableDigitalSignature]);

  return (
    <div className={styles.waiverAgreementSignatureCanvas}>
      <div className={styles.waiverAgreementSignatureCanvasInner}>
        {enableDigitalSignature ? (
          <DigitalSignatureCanvas signature={signature} sigPad={sigPad} />
        ) : (
          <ReactSignatureCanvas canvasProps={{ width: 500, height: 200 }} backgroundColor="white" ref={sigPad} />
        )}
      </div>
      {/* <button onClick={clearSignature}>Clear</button> */}
    </div>
  );
};

export default SignatureCanvas;
