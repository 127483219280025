import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getActivityListByConfirmationNumber, CheckGuestRegistrationInActivity } from '../../../api';

import { ASSIGN_ACTIVITIES_TYPES } from '../actions';
import {
  getActivitiesByConfirmationCodeFailure,
  getActivitiesByConfirmationCodeSuccess,
  CheckGuestRegistrationInActivitySuccessAction,
  CheckGuestRegistrationInActivityFailureAction,
} from '../actions/assignActivitiesActions';
import { CheckGuestRegistrationInActivityType } from '../types/assignActivities';

export function* getActivitiesByConfirmationCodeSagaWorker({ payload }: { payload: string; type: string }) {
  try {
    const { data }: { data: any } = yield call(getActivityListByConfirmationNumber, payload);

    yield put(getActivitiesByConfirmationCodeSuccess({ ...data }));
  } catch (err) {
    yield put(getActivitiesByConfirmationCodeFailure());
  }
}

export function* CheckGuestRegistrationInActivitySagaWorker({ payload }: { payload: any; type: string }) {
  try {
    const { activityIds, customerIds } = payload;

    const { data }: { data: any } = yield call(CheckGuestRegistrationInActivity, customerIds, activityIds);

    yield put(CheckGuestRegistrationInActivitySuccessAction({ ...data }));
  } catch (err) {
    yield put(CheckGuestRegistrationInActivityFailureAction());
  }
}

export default function* assignActivitiesSaga() {
  yield all([
    yield takeLatest(
      ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST,
      getActivitiesByConfirmationCodeSagaWorker,
    ),
    yield takeLatest(
      ASSIGN_ACTIVITIES_TYPES.CHECK_GUEST_REGISTRATION_IN_ACTIVITY,
      CheckGuestRegistrationInActivitySagaWorker,
    ),
  ]);
}
