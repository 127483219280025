import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../../api';
import { createCartWorker } from './createCartSaga';
import {
  ADD_ITEM_TO_CART_TYPES,
  addItemToCartActionFailure,
  addItemToCartActionSuccess,
  addComboItemToCartActionSuccess,
} from '../actions';

import { updateStatusNotEnoughTimeSlots } from '../../PurchaseFlow/actions';

import { PRODUCT_TYPE, ROUTES } from '../../../constants';
import { history } from '../../../App';
import { membershipRequestAction, nonschedulableMembershipRequestAction } from '../../Membership/actions';

export function* addItemToCartWorker({ payload }: any) {
  const {
    productType,
    item,
    isRequiredMembership,
    isSchedulable,
    checkDetails,
    isComboProduct,
    data,
    onSuccess,
    isPerResource,
    comboGroupId,
  } = payload;
  try {
    const { customerStore, cartStore } = yield select(store => store);
    let checkId = cartStore.checkId;
    let customersMembership = [];
    if (!checkId) {
      checkId = yield call<any>(createCartWorker);
    }

    if (isRequiredMembership) {
      if (!item?.isSchedulable && !isSchedulable && item) {
        const {
          data: { customerMemberships },
        } = yield call<any>(Api.validateCustomerGroupMembership, {
          checkId,
          productId: item.productId,
          customerId: customerStore.custId,
        });

        customersMembership = customerMemberships;
      } else {
        let productsCustomers;
        if (checkDetails) {
          productsCustomers = checkDetails.map((product): any => {
            return { productId: product.productId, customerId: product.custId };
          });
        } else {
          productsCustomers = [{ productId: item.productId, customerId: customerStore.custId }];
        }

        const {
          data: { customerMemberships },
        } = yield call<any>(Api.validateCustomerMembership, {
          checkId,
          productsCustomers,
        });
        customersMembership = customerMemberships;
      }
    }

    const hasMembership = customersMembership.filter((customer): any => !customer.customerHasMembership);
    if (!hasMembership.length) {
      const payloadData = {
        checkId,
        checkDetails: [],
      };

      if (
        (productType === PRODUCT_TYPE.REGULAR ||
          productType === PRODUCT_TYPE.FOOD ||
          productType === PRODUCT_TYPE.GIFT_CARD ||
          productType === PRODUCT_TYPE.INTER_CARD ||
          productType === PRODUCT_TYPE.SEMNOX_GAME_CARD ||
          productType === PRODUCT_TYPE.EMBEDED_GAME_CARD ||
          productType === PRODUCT_TYPE.ENTITLE) &&
        !payload.checkDetails
      ) {
        payloadData.checkDetails.push({
          ...item,
          ...(customersMembership.length &&
            customersMembership[0]?.customerId === customerStore.custId && {
              membershipProductId: customersMembership[0]?.membershipProducts[0]?.membershipProductId,
            }),
          ...(customerStore.custId && { custId: customerStore.custId }),
        });
      }

      if (productType === PRODUCT_TYPE.POINT || productType === PRODUCT_TYPE.MEMBERSHIP || payload.checkDetails) {
        if (customersMembership.length) {
          const hasMembershipCheckDetails = payload.checkDetails.map((product): any => {
            return {
              ...product,
              membershipProductId: customersMembership.filter((membership): any => {
                return membership.customerId === product.custId;
              })[0]?.membershipProducts[0]?.membershipProductId,
            };
          });
          if (hasMembershipCheckDetails) {
            payloadData.checkDetails = hasMembershipCheckDetails;
          }
        } else {
          payloadData.checkDetails = payload.checkDetails;
        }
      }

      let cartProducts;

      if (isComboProduct) {
        cartProducts = yield call<any>(Api.addCombosToCart, {
          ...payloadData,
          data,
          isPerResource: isPerResource ? isPerResource : false,
          comboGroupId,
        });
      } else {
        cartProducts = yield call<any>(Api.addToCart, { ...payloadData });
      }

      if (cartProducts?.data?.errorCode === -1) {
        yield put<any>(updateStatusNotEnoughTimeSlots({ notEnoughTimeSlots: true }));
      }

      if (!cartProducts?.data?.errorCode) {
        if (isComboProduct) {
          yield put<any>(addComboItemToCartActionSuccess(cartProducts.data.result.value));
          !!onSuccess && onSuccess();
        } else {
          yield put<any>(addItemToCartActionSuccess(cartProducts.data));
          history.replace(ROUTES.PRODUCTS);
        }
      }
    } else {
      let product;
      if (payload.checkDetails) {
        product = { productType, isSchedulable, items: [...payload.checkDetails] };
      } else {
        product = {
          productType,
          isSchedulable,
          items: [{ ...item, ...(customerStore.custId && { custId: customerStore.custId }) }],
        };
      }
      yield put<any>(
        membershipRequestAction({
          hasMembership,
          product,
        }),
      );
      yield put<any>(addItemToCartActionFailure());
    }
  } catch (err) {
    yield put<any>(addItemToCartActionFailure());
  }
}

export function* addNonschedulableProductsToCartWorker({ payload }: any) {
  const { checkId, comboId, custIds, products, isPerResource, comboGroupId, areOnlyNonschedulableProducts } = payload;
  const {
    customerStore,
    productsByCategoryStore,
    customerGroup: { members },
  } = yield select(store => store);

  try {
    let productsCustomers;
    productsCustomers = custIds
      .map(id => {
        return products.map(product => {
          return {
            customerId: id,
            productId: product.productID,
          };
        });
      })
      .reduce((acc, val) => acc.concat(val), []);

    const {
      data: { customerMemberships },
    } = yield call<any>(Api.validateCustomerMembership, {
      checkId,
      productsCustomers,
    });
    let customersMembership = customerMemberships;

    const hasMembership = customersMembership.filter(
      (customer): any => !customer.customerHasMembership && customer.membershipProducts.length > 0,
    );

    const result = customersMembership.reduce((acc, item) => {
      const found = acc.find(x => x.customerId === item.customerId);

      if (found) {
        found.membershipProducts.push(...item.membershipProducts);
      } else {
        acc.push({ ...item });
      }

      return acc;
    }, []);

    if (!hasMembership.length) {
      const data = yield call<any>(Api.addNonschedulableProductsToCart, {
        checkId,
        productComboId: comboId,
        customerIds: custIds,
        products,
        isPerResource: isPerResource || false,
        comboGroupId,
      });
      yield put<any>(addComboItemToCartActionSuccess(data.data.result.value));

      if (areOnlyNonschedulableProducts) {
        yield history.replace(ROUTES.PRODUCTS);
      }
    } else {
      yield put<any>(
        nonschedulableMembershipRequestAction({
          hasMembership: result,
          product: products[0],
          successProduct: {
            checkId,
            productComboId: comboId,
            customerIds: custIds,
            products,
            isPerResource: isPerResource || false,
            comboGroupId,
          },
        }),
      );
    }
  } catch (err) {
    yield put<any>(addItemToCartActionFailure());
  }
}

export default function* addItemToCartSaga() {
  yield all([
    yield takeLatest(ADD_ITEM_TO_CART_TYPES.REQUEST, addItemToCartWorker),
    yield takeLatest(ADD_ITEM_TO_CART_TYPES.NONSCHEDULABLE, addNonschedulableProductsToCartWorker),
  ]);
}
