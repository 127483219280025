import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { PRODUCT_TYPE, ROUTES } from '../../constants';
import { addItemToCartAction } from '../../store/Cart/actions';
import Modal from '../Modal';
import InputNumber from '../InputNumber';
import Button from '../Button';
import NavigationPanel from '../NavigationPanel';
import styles from './styles.module.scss';
import ProductOptions from '../ProductOptions';
import * as Api from '../../api';
import { getItemsApiCredentials, getPrice } from '../../utils/core';
import { history } from '../../App';

interface Props {
  isOpen?: boolean;
  productDetail?: any;
  cancelHandler: () => void;
  handleAddToCart?: () => void;
  quantityIsHide?: boolean;
  disabledAddToCart?: boolean;
  setQuickProductViewActive?: React.Dispatch<React.SetStateAction<boolean>>;
  setChooseParticipantState?: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setPromptsData?: ({ quantity, promptData, members, payload }: any) => void;
}

const QuickProductView = ({
  disabledAddToCart = false,
  productDetail,
  cancelHandler,
  handleAddToCart,
  setChooseParticipantState,
  setQuickProductViewActive,
  quantityIsHide = false,
  setPromptsData,
  setLoading,
}: Props) => {
  const dispatch = useDispatch();

  const { customerStore, terminalSettings } = useSelector(state => state);
  const [isOpenProductOptions, setIsOpenProductOptions] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [productOptions, setProductOptions] = React.useState([]);
  const [specialOptions, setSpecialOptions] = React.useState('');

  const productCover = () => {
    if (productDetail.highResImagePath) {
      return productDetail.highResImagePath;
    }
    return `data:image/jpeg;base64,${productDetail.largeIcon}`;
  };

  const addToCartQuickProduct = ({ payload }) => {
    dispatch(addItemToCartAction({ ...payload }));
    closeQuickProductView();
  };

  const changeQuantity = (type: string) => {
    if (type === 'plus') {
      setQuantity(prevState => prevState + 1);
    }
    if (type === 'minus' && quantity - 1 > 0) {
      setQuantity(prevState => prevState - 1);
    }
  };

  const addToCart = async () => {
    if (quantity <= 0) return;

    setLoading(true);
    const {
      data: { prompts },
    } = await Api.fetchProductPrompts(productDetail.productId);
    const payload = {
      productType: productDetail.productType,
      isRequiredMembership: productDetail.isRequiredMembership,
      isSchedulable: productDetail.isSchedulable,
      item: {
        isSchedulable: productDetail.isSchedulable,
        scheduleTime: '',
        productId: productDetail.productId,
        quantity: `${quantity}`,
        ...((productOptions.length || specialOptions) && {
          subItems: [...productOptions, ...(specialOptions ? specialOptions.split(/\n/) : [])],
        }),
      },
    };
    if (!prompts.length) {
      addToCartQuickProduct({ payload });
    } else {
      await setPromptsData({ quantity, payload, promptData: prompts });
    }
    setLoading(false);
  };

  const closeQuickProductView = () => {
    setQuantity(1);
    cancelHandler();
    // history.replace(ROUTES.PRODUCTS);
  };

  const doneProductOptions = () => {
    setIsOpenProductOptions(false);
  };

  const closeProductOptions = () => {
    setProductOptions([]);
    setIsOpenProductOptions(false);
  };

  const customizeProduct = () => {
    setIsOpenProductOptions(true);
  };

  const updateSelectedProductOptions = (option: string) => {
    if (productOptions.includes(option)) {
      setProductOptions([...productOptions.filter(item => item !== option)]);
    } else {
      setProductOptions([...productOptions, option]);
    }
  };

  const changeCustomOption = (newValue: React.SetStateAction<string>) => {
    setSpecialOptions(newValue);
  };

  const onRightButtonClickHandle = () => {
    if (productDetail?.isCombo && terminalSettings.enableCombos) {
      history.replace(`${ROUTES.PRODUCTS}/combos/${productDetail.categoryID}`);
      return;
    }

    if (disabledAddToCart) {
      setQuickProductViewActive(false);
      if (setChooseParticipantState) {
        setChooseParticipantState(true);
      }
      if (handleAddToCart) {
        handleAddToCart();
      }
      return null;
    } else {
      setQuickProductViewActive(false);
      return addToCart();
    }
  };

  return (
    <>
      <Modal zIndex={102} isOpen={!isOpenProductOptions} closeButtonHandler={closeQuickProductView}>
        <div className={styles.quickProductView}>
          <div className={styles.quickProductViewHeader}>
            <div className={styles.quickProductViewHeaderLS}>
              <h2>{productDetail.description}</h2>
            </div>
            <div className={styles.quickProductViewHeaderRS}>
              <div className={styles.price}>
                <div className={styles.priceInner}>
                  <div className={styles.priceValue}>
                    ${getPrice(productDetail, customerStore.priceLevel, !!productDetail?.isCombo)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.quickProductViewContent}>
            <div className={styles.quickProductViewInformationLeft}>
              <div className={styles.productCover}>
                <img src={productCover()} alt="" />
              </div>
              {productDetail.productType === PRODUCT_TYPE.FOOD && productDetail.subItems && (
                <Button handleClick={customizeProduct} customClass={styles.сustomizeButton} theme="red">
                  Customize
                </Button>
              )}
            </div>
            <div className={styles.quickProductViewInformationRight}>
              <div className={styles.quickProductDescription}>
                <div>
                  {productDetail.activityDescription && (
                    <p
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(`${productDetail.activityDescription}`),
                      }}
                    />
                  )}
                  {productDetail.explanation}
                </div>
              </div>
              {!quantityIsHide && (
                <div className={styles.changeQuantity}>
                  <InputNumber value={quantity} handleClick={changeQuantity} />
                </div>
              )}
            </div>
          </div>
          <NavigationPanel
            onLeftButtonClick={closeQuickProductView}
            leftButtonTitle="Cancel"
            onRightButtonClick={onRightButtonClickHandle}
            rightButtonTitle="Add to cart"
            customClass={styles.navigationPanel}
          />
        </div>
      </Modal>
      <ProductOptions
        doneProductOptions={doneProductOptions}
        productTitle={productDetail?.description}
        onCloseHandler={closeProductOptions}
        options={productDetail?.subItems}
        selectedOptions={productOptions}
        isOpen={isOpenProductOptions}
        onClickOption={updateSelectedProductOptions}
        valueCustomOption={specialOptions}
        onChangeCustomOption={changeCustomOption}
      />
    </>
  );
};

QuickProductView.defaultProps = {
  isOpen: false,
};

export default QuickProductView;
