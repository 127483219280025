import { ActivityType, CheckGuestRegistrationInActivityType } from '../types/assignActivities';

export const ASSIGN_ACTIVITIES_TYPES = {
  GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST',
  GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS',
  GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE',
  GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET: 'customer/assignActivities/GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET',
  CHECK_GUEST_REGISTRATION_IN_ACTIVITY: 'customer/assignActivities/CHECK_GUEST_REGISTRATION_IN_ACTIVITY',
  CHECK_GUEST_REGISTRATION_IN_ACTIVITY_SUCCESS:
    'customer/assignActivities/CHECK_GUEST_REGISTRATION_IN_ACTIVITY_SUCCESS',
  CHECK_GUEST_REGISTRATION_IN_ACTIVITY_FAILURE:
    'customer/assignActivities/CHECK_GUEST_REGISTRATION_IN_ACTIVITY_FAILURE',
};

export const getActivitiesByConfirmationCodeAction = (payload: string) => ({
  type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_REQUEST,
  payload,
});

export const getActivitiesByConfirmationCodeSuccess = (payload: ActivityType[]) => ({
  type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS,
  payload,
});

export const getActivitiesByConfirmationCodeFailure = () => ({
  type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE,
});

export const getActivitiesByConfirmationCodeReset = () => ({
  type: ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET,
});

export const CheckGuestRegistrationInActivityAction = (payload: CheckGuestRegistrationInActivityType) => ({
  type: ASSIGN_ACTIVITIES_TYPES.CHECK_GUEST_REGISTRATION_IN_ACTIVITY,
  payload,
});

export const CheckGuestRegistrationInActivitySuccessAction = (payload: any) => ({
  type: ASSIGN_ACTIVITIES_TYPES.CHECK_GUEST_REGISTRATION_IN_ACTIVITY_SUCCESS,
  payload,
});

export const CheckGuestRegistrationInActivityFailureAction = () => ({
  type: ASSIGN_ACTIVITIES_TYPES.CHECK_GUEST_REGISTRATION_IN_ACTIVITY_FAILURE,
});
