import { GetComboActionPayloadTypes } from '../types/getCombos';

export const GET_COMBOS_TYPES = {
  REQUEST: 'GET_COMBOS_REQUEST',
  SUCCESS: 'GET_COMBOS_REQUEST_SUCCESS',
  FAILURE: 'GET_COMBOS_REQUEST_FAILURE',
};

export const getComboAction = (payload: GetComboActionPayloadTypes) => ({
  type: GET_COMBOS_TYPES.REQUEST,
  payload,
});

export const getComboActionSuccess = (payload: any) => ({
  type: GET_COMBOS_TYPES.SUCCESS,
  payload,
});

export const getComboActionFailure = () => ({
  type: GET_COMBOS_TYPES.FAILURE,
});
