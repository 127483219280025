import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Iframe from 'react-iframe';
import moment from 'moment';
import cn from 'classnames';
import WizardFormDatePicker from '../WizardFormDatePicker';
import WizardFormSelect from '../WizardFormSelect';
import WizardFormField from '../WizardFormField';
import WizardFormNumeric from '../WizardFormNumeric';
import WizardFormCheckbox from '../WizardFormCheckbox';
import WizardFormPicture from '../WizardFormPicture';
import SelectableCard from '../SelectableCard';
import Radio from '../Radio';
import Checkbox from '../Checkbox';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles.module.scss';
import WizardFormTypeSelector from '../WizardFormTypeSelector';
import WizardFormItemSelect from '../WizardFormItemSelect';
import WizardFormDropdownField from '../WizardFormDropdownField/WizardFormDropdownField';

interface Props {
  type?:
    | 'text'
    | 'email'
    | 'date'
    | 'select'
    | 'checkbox'
    | 'radio'
    | 'itemSelect'
    | 'numeric'
    | 'picture'
    | 'typeSelector'
    | 'dropdown';
  fieldName: string;
  value: number | string | null;
  label: any;
  placeholder?: string;
  required?: boolean;
  options?: any[];
  valueField?: any;
  displayField?: string;
  onChange: (fieldName: any, newFieldValue: any) => void;
  errorMessage?: string;
  isActive: boolean;
  doNotMail?: boolean;
  setDoNotMail?: (value: boolean) => void;
  goToNextStep: any;
  goToPrevStep: any;
  keyboardLayout?: string;
}

const WizardFormStep = ({
  type,
  fieldName,
  value,
  label,
  required,
  options,
  valueField,
  displayField,
  onChange,
  errorMessage,
  isActive,
  doNotMail,
  setDoNotMail,
  goToNextStep,
  goToPrevStep,
  placeholder,
  keyboardLayout,
}: Props) => {
  const { registrationSettings: registrationSettingsStore } = useSelector(state => state);
  const [isOpenPrivacyPolicy, setIsOpenPrivacyPolicy] = useState(false);
  const colorBackButton: string = type === 'checkbox' || type === 'radio' || type === 'picture' ? 'grey' : 'white';
  return (
    <Fragment>
      {isActive && (
        <div className={styles.wizardFormStep}>
          {type === 'date' && (
            <WizardFormDatePicker
              fieldName={label}
              value={value}
              label={label}
              placeholder=""
              required={required}
              onChange={newValue => onChange(fieldName, moment(newValue).format('YYYY-MM-DD'))}
            />
          )}
          {type === 'email' && (
            <Fragment>
              <Modal
                isOpen={isOpenPrivacyPolicy}
                showCloseButton
                closeButtonHandler={() => setIsOpenPrivacyPolicy(prevState => !prevState)}
              >
                <div>
                  <Iframe
                    url={registrationSettingsStore.privacyPolicyUrl}
                    width="600px"
                    height="630px"
                    id="myId"
                    className="myClassname"
                    display="block"
                    position="relative"
                  />
                </div>
              </Modal>
              <WizardFormField
                fieldName={fieldName}
                value={value}
                label={label}
                placeholder=""
                invalid={false}
                errorMessage={errorMessage}
                required={required}
                onChange={onChange}
                keyboardLayout="email"
                enterClick={goToNextStep}
              >
                <div className={styles.emailCheckboxText}>
                  <div>
                    <Checkbox
                      state={doNotMail}
                      label="I would like to receive email marketing and special offers"
                      handleClick={() => setDoNotMail(!doNotMail)}
                    />
                    {registrationSettingsStore.emailCheckboxText && (
                      <div className={styles.specialEmailText}>{registrationSettingsStore.emailCheckboxText}</div>
                    )}
                  </div>
                </div>
              </WizardFormField>
              <div className={styles.privacyPolicyLink}>
                <span onClick={() => setIsOpenPrivacyPolicy(prevState => !prevState)}>Privacy Policy</span>
              </div>
            </Fragment>
          )}
          {type === 'radio' && (
            <WizardFormCheckbox titleOfStap={label} required={required} errorMessage={errorMessage}>
              {options.map((item, index) => (
                <div key={index} className={styles.mb40}>
                  <Radio
                    state={`${value}` === `${item[valueField]}`}
                    label={item[displayField]}
                    handleClick={() => onChange(fieldName, item[valueField])}
                  />
                </div>
              ))}
            </WizardFormCheckbox>
          )}
          {type === 'itemSelect' && (
            <WizardFormItemSelect titleOfStap={label} required={required} errorMessage={errorMessage}>
              <div className={styles.timeSlotsList}>
                {options.map((item, index) => (
                  <div key={index} className={styles.mb40}>
                    <SelectableCard
                      text={item[displayField]}
                      isActive={`${value}` === `${item[valueField]}`}
                      handleClick={() => onChange(fieldName, item[valueField])}
                    ></SelectableCard>
                  </div>
                ))}
              </div>
            </WizardFormItemSelect>
          )}
          {type === 'checkbox' && (
            <WizardFormCheckbox titleOfStap={label} required={required}>
              <div>
                <div>
                  <Checkbox state={!!value} label={label} handleClick={() => onChange(fieldName, !value)} />
                </div>
              </div>
            </WizardFormCheckbox>
          )}
          {type === 'select' && (
            <WizardFormSelect
              value={value}
              source={options}
              valueField={valueField}
              errorMessage={errorMessage}
              required={required}
              displayField={displayField}
              fieldName={fieldName}
              label={label}
              handleChange={newValue => onChange(fieldName, newValue.value)}
            />
          )}
          {type === 'typeSelector' && (
            <WizardFormTypeSelector
              value={value}
              source={options}
              valueField={valueField}
              errorMessage={errorMessage}
              required={required}
              displayField={displayField}
              fieldName={fieldName}
              label={label}
              handleChange={onChange}
              keyboardLayout={keyboardLayout}
            />
          )}
          {type === 'dropdown' && (
            <WizardFormDropdownField
              fieldName={fieldName}
              value={value}
              label={label}
              placeholder={placeholder}
              invalid={false}
              errorMessage={errorMessage}
              required={required}
              onChange={onChange}
              enterClick={goToNextStep}
              items={options}
            />
          )}
          {type === 'text' && (
            <WizardFormField
              fieldName={fieldName}
              value={value}
              label={label}
              placeholder=""
              invalid={false}
              errorMessage={errorMessage}
              required={required}
              onChange={onChange}
              enterClick={goToNextStep}
            />
          )}
          {type === 'numeric' && (
            <WizardFormNumeric
              fieldName={fieldName}
              value={value}
              label={label}
              placeholder=""
              invalid={false}
              errorMessage={errorMessage}
              required={required}
              onChange={onChange}
              enterClick={goToNextStep}
            />
          )}
          {type === 'picture' && <WizardFormPicture fieldName={fieldName} value={value} onChange={onChange} />}
          <div className={styles.navigation}>
            <Button
              theme={colorBackButton}
              handleClick={goToPrevStep}
              customClass={cn(styles.navigationButton, styles.navigationButtonBack)}
            >
              Back
            </Button>
            <div className={styles.navigationFilter} />
            <Button
              theme="red"
              handleClick={goToNextStep}
              customClass={cn(styles.navigationButton, styles.navigationButtonContinue)}
            >
              Continue
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

WizardFormStep.defaultProps = {
  type: 'text',
  options: [],
  valueField: null,
  displayField: '',
  required: false,
  doNotMail: false,
  setDoNotMail: () => {},
};

export default WizardFormStep;
