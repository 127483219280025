import { ComboProduct } from 'typings/product';

function updateProducts(products: ComboProduct[], newProducts: ComboProduct[]) {
  const tempProducts = [...products];

  newProducts.forEach(product => {
    const findIndex = tempProducts.findIndex(
      obj => obj.productID === product.productID && obj.comboID === product.comboID,
    );
    if (findIndex !== -1) {
      // Replace the existing object with the new one
      tempProducts[findIndex] = product;
    } else {
      // Push the element to the nested array
      tempProducts.push(product);
    }
  });

  return tempProducts;
}

export { updateProducts };
