import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { startPaymentAction } from '../../store/Payment/actions';
import CartItemsList from './CartItemsList';
import CartItem from './CartItem';
import CartSummary from './CartSummary';
import Button from '../Button';
import KDSform from '../KDSform';
import styles from './styles.module.scss';
import useSticky from '../../hooks/useSticky';
import cn from 'classnames';
import { useCartItemsListHeight } from '../../hooks';
import { IParams, Member } from 'typings/product';
import { CheckComboProductCart, CheckDetailsPerResource } from 'typings/cart';
import ComboCartItemPerResource from './ComboCartItemPerResource/ComboCartItemPerResource';
import ComboCartItemPerPerson from './ComboCartItemPerPerson/ComboCartItemPerPerson';

interface IProps {
  members?: Member[];
  comboGroupId?: number;
}

const Cart = ({ members, comboGroupId }: IProps) => {
  const params = useParams<IParams>();

  const cartRef = React.useRef(null);

  const { isSticky } = useSticky(cartRef, 60);
  const cartItemsListHeight = useCartItemsListHeight({ isSticky });

  const dispatch = useDispatch();
  const {
    cartStore: {
      checkDetails,
      comboCheckDetails,
      comboCheckDetailsPerResource,
      checkTotal,
      checkSubtotal,
      checkTax,
      loading,
      checkClubSpeedKioskFeeTotal,
    },
    customerStore,
  } = useSelector(state => state);

  const [formIsOpen, setFormIsOpen] = React.useState(false);

  const startCheckout = () => {
    dispatch(startPaymentAction({}));

    if (!customerStore?.custId) {
      setFormIsOpen(true);
    }
  };

  const disableCheckoutButton = () => {
    if (!checkDetails?.length && !comboCheckDetails?.length && !comboCheckDetailsPerResource?.length) {
      return true;
    }

    // In Product details page user should can not checkout
    if (!!params?.comboId) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className={styles.cart__wrapper}>
        <div className={cn(styles.cart, { [styles.cart__isSticky]: isSticky })} ref={cartRef}>
          <div id="cartTitle" className={styles.cartTitle}>
            <span>Cart</span>
          </div>
          <div
            className={styles.cartItemsInner}
            style={{ height: cartItemsListHeight > 0 ? `${cartItemsListHeight}px` : '100%' }}
          >
            <CartItemsList>
              {checkDetails?.map((item: any) => (
                <CartItem
                  key={item.checkDetailId}
                  currentCartItem={item}
                  checkDetailId={item.checkDetailId}
                  productId={item.productId}
                  productType={item.productType}
                  custId={item.p_CustId || item.m_CustId}
                />
              ))}
              {comboCheckDetailsPerResource?.map((items: CheckDetailsPerResource, index: number) => (
                <ComboCartItemPerResource
                  items={items}
                  members={members}
                  comboId={params?.comboId}
                  key={`${index} ${items.comboId}`}
                  activeComboGroupId={comboGroupId}
                />
              ))}
              {comboCheckDetails?.map((items: CheckComboProductCart, index: number) => (
                <ComboCartItemPerPerson
                  combosQuantity={
                    comboCheckDetails.filter(
                      combo =>
                        combo.comboId == items.comboId &&
                        items.comboGroupId === combo.comboGroupId &&
                        combo.comboForCustId,
                    ) || []
                  }
                  items={items}
                  members={members}
                  comboId={params?.comboId}
                  key={`${index} ${items.comboId}`}
                  activeComboGroupId={comboGroupId}
                />
              ))}
            </CartItemsList>
          </div>
          <div id="cartTotalAmount">
            <div className={styles.cartLine} />
            <CartSummary
              subtotal={checkSubtotal}
              tax={checkTax}
              total={checkTotal + checkClubSpeedKioskFeeTotal}
              fees={checkClubSpeedKioskFeeTotal}
            />
            <div className={styles.cartCheckout}>
              <Button
                handleClick={startCheckout}
                disabled={disableCheckoutButton()}
                customClass={styles.buttonCheckout}
                theme="red"
              >
                Checkout
              </Button>
            </div>
          </div>
          {loading && (
            <div className={styles.loaderWrapper}>
              <div className={styles.loader} />
            </div>
          )}
        </div>
      </div>
      <KDSform isOpen={formIsOpen} setIsOpen={setFormIsOpen} />
    </>
  );
};

export default Cart;
