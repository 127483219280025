import request from '../services/Api';
import { AxiosResponse } from 'axios';
import { ConfirmationCodeCheckResponse } from '../store/Customer/types/confirmationCode';

export const findCustomersByPhone = (cell: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Customers/GetCustomersByPhone?PhoneNumber=${cell}`);

export const findCustomerByNameAndDOB = (fName: string, lName: string, birthDate: string): Promise<AxiosResponse> =>
  request(`CoreAPI/Customers/GetCustomerByNameAndDOB?FirstName=${fName}&LastName=${lName}&DateOfBirth=${birthDate}`);

export const fetchCustomerById = (custId: any): Promise<AxiosResponse> => request.get(`CoreAPI/Customers/${custId}`);

export const createCustomer = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Customers', data);

export const updateCustomer = (custId: number | string, data: any): Promise<AxiosResponse> =>
  request.put(`CoreAPI/Customers/${custId}`, data);

export const deleteCustomer = (custId: number): Promise<AxiosResponse> => request.delete(`CoreAPI/Customers/${custId}`);

export const checkCustomerEmailAddress = (emailAddress: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Customers/EmailAddressAvailable?EmailAddress=${emailAddress}`);

export const verifyPinCustomer = (custId: number, hashedPin: string): Promise<AxiosResponse> =>
  request.get(`CoreAPI/Customers/VerifyPin?custId=${custId}&HashedPin=${hashedPin}`);

export const sendResetPinCode = (custId: number): Promise<AxiosResponse> =>
  request.post(`CoreAPI/Customers/SendResetCode?CustId=${custId}`);

export const verifyResetCode = (custId: number, resetCode: string): Promise<AxiosResponse> =>
  request.post(`CoreAPI/Customers/VerifyResetCode?custId=${custId}&ResetCode=${resetCode}`);

export const updatePinCode = (custId: number, resetCodeId: string, hashedPin: string): Promise<AxiosResponse> =>
  request.post(`CoreAPI/Customers/ResetPin?custId=${custId}&ResetCodeId=${resetCodeId}&HashedPin=${hashedPin}`);

export const validateCustomerMembership = ({ checkId, productsCustomers }: any) =>
  request.post('CoreAPI/SelfServeKiosk/ValidateCustomerMembership', { checkId, productsCustomers });

export const validateCustomerGroupMembership = (data: any): Promise<AxiosResponse> =>
  request.post('CoreApi/SelfServeKiosk/ValidateCustomerGroupMembership', data);

export const updatePhoneAndPin = (
  custId: number,
  fName: string,
  lName: string,
  birthDate: string,
  cell: string,
  pin: string,
): Promise<AxiosResponse> =>
  request.put(
    `CoreAPI/Customers/UpdatePinAndPhoneNumber?CustId=${custId}&FirstName=${fName}&LastName=${lName}&DateOfBirth=${birthDate}&PhoneNumber=${cell}&HashedPin=${pin}`,
  );

export const fetchCustomerPicture = (custId: number | string): Promise<any> =>
  request.get(`CoreAPI/CustomerPicture/?custId=${custId}`);

export const customersSchedulesAvailableInKiosk = (
  productId: number | string,
  scheduleTime: object | string,
  checkId: number | string,
): Promise<AxiosResponse> =>
  request.get(
    `CoreAPI/Customers/CustomersSchedulesAvailableInKiosk/?ProductId=${productId}&ScheduleTime=${scheduleTime}&CheckId=${checkId}`,
  );

export const uploadCustomerPicture = (custId: number | string, customerImage: string): Promise<any> =>
  request.post('CoreAPI/CustomerPicture', { custId, customerImage });

export const verifyActivityByConfrimationNumber = (
  confirmationCode: string,
): Promise<AxiosResponse<ConfirmationCodeCheckResponse>> => {
  return request.post(`CoreAPI/Customers/VerifyActivityByConfrimationNumber`, { confirmationCode });
};

export const verifyEventByConfrimationNumber = (
  confirmationCode: string,
): Promise<AxiosResponse<ConfirmationCodeCheckResponse>> => {
  return request.post(`CoreAPI/EventHeats/VerifyEventByConfrimationNumber`, { confirmationCode });
};

export const updateCustomerBirthDate = (params: { birthDate: Date; custId: number }): Promise<AxiosResponse<any>> => {
  return request.post(`CoreAPI/Customers/UpdateCustomerBirthDate`, { ...params });
};

export const getCurrentDayEvents = (): Promise<AxiosResponse<any>> => {
  return request.post(`CoreAPI/Customers/GetTodaysEvents`);
};

export const checkIfEventExists = (params: { eventId: string }): Promise<AxiosResponse> => {
  return request.post(`CoreAPI/EventHeats/CheckIfEventExists`, { ...params });
};

export const addGuestInEvent = (params: { eventId: string; custId: string }): Promise<AxiosResponse> => {
  return request.post(`CoreAPI/EventHeats/AddGuestInEvent`, { ...params });
};

export const addGuestsInActivity = (
  data: any,
): Promise<AxiosResponse<{ success: boolean; data: null; message: null | string }>> => {
  return request.post(`CoreAPI/Customers/AddGuestsInActivity`, { ...data });
};

export const getActivityListByConfirmationNumber = (
  confirmationCode: string,
): Promise<AxiosResponse<ConfirmationCodeCheckResponse>> => {
  return request.get(`CoreAPI/Customers/GetActivityListByConfirmationNumber`, { params: { confirmationCode } });
};

export const CheckGuestRegistrationInActivity = (
  customerIds: number[],
  activityIds: number[],
): Promise<AxiosResponse<any>> => {
  return request.post(`CoreAPI/Customers/CheckGuestRegistrationInActivity`, {
    params: { customerIds, activityIds },
  });
};
