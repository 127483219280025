const comboCategory = {
  id: 'combos',
  title: 'Combos',
  description: 'Combos',
  enabled: true,
  seq: 23,
  largeIcon: '',
  deleted: false,
  isCombo: true,
  showInSelfServeKiosk: true,
  showInFoodSelfServeKiosk: false,
  highResImagePath: 'https://cdn.clubspeed.com/booking/default-combo.png',
  productType: '',
  explanation:
    'Each combo is intended for a single guest. On the following screens, please select a time slot for each activity in your combo. Time slots selected will be applied for all chosesn participants.',
};

export { comboCategory };
