import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import styles from './styles.module.scss';

interface Props {
  isOpen?: boolean;
  title?: string;
  titleButtonOk?: string;
  titleButtonCancel?: string;
  children: React.ReactNode;
  handleReturnClick?: () => void;
  handleContinueClick?: () => void;
  disabledContinue?: boolean;
}

const Confirm = ({
  isOpen,
  title,
  titleButtonOk,
  titleButtonCancel,
  children,
  handleReturnClick,
  handleContinueClick,
  disabledContinue = false,
}: Props) => {
  return (
    <Modal isOpen={isOpen} closeButtonHandler={handleReturnClick}>
      <div className={styles.container}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          {!!titleButtonCancel && (
            <Button theme="grey" customClass={styles.button} handleClick={handleReturnClick}>
              {titleButtonCancel}
            </Button>
          )}
          <Button disabled={disabledContinue} theme="red" customClass={styles.button} handleClick={handleContinueClick}>
            {titleButtonOk}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Confirm.defaultProps = {
  isOpen: false,
  title: '',
  titleButtonOk: 'Ok',
  titleButtonCancel: 'Cancel',
  handleReturnClick: () => {},
  handleContinueClick: () => {},
};

export default Confirm;
