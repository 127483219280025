import React, { useState } from 'react';
import styles from './styles.module.scss';
import NavigationPanel from '../NavigationPanel';
import ProductItem from '../ProductItem';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToCartAction, addNonschedulableProductsToCart } from '../../store/Cart/actions';
import { membershipResetAction } from '../../store/Membership/actions/membership';
import Avatar from '../Avatar';
import WarningAlert from '../WarningAlert';
import QuickProductView from '../QuickProductView';
import HeaderPanel from '../HeaderPanel/HeaderPanel';
import { ComboProduct } from 'typings/product';
import { history } from '../../App';
import { ROUTES } from '../../constants';

interface ProductMembershipRequiredProps {
  chooseParticipant?: any;
  closeChooseParticipant?: any;
  productDetails?: ComboProduct;
  comboGroupId?: number;
  hideBackButton?: boolean;
  areOnlyNonschedulableProducts?: () => boolean;
  handleBackButton?: () => void;
  hideCancelButton?: boolean;
}

const ProductMembershipRequired = ({
  chooseParticipant,
  closeChooseParticipant,
  productDetails,
  comboGroupId,
  hideBackButton = false,
  hideCancelButton = false,
  handleBackButton,
  areOnlyNonschedulableProducts,
}: ProductMembershipRequiredProps) => {
  const closeProductMembershipRequired = () => {
    dispatch(membershipResetAction());
    if (closeChooseParticipant) {
      closeChooseParticipant();
    }
  };

  const dispatch = useDispatch();

  const {
    membershipStore: { memberships, customers, product },
    cartStore: { checkId },
  } = useSelector(state => state);

  const handleAddToCart = () => {
    let payload = {};

    if (!!productDetails?.comboID) {
      payload = {
        checkId: checkId,
        comboId: productDetails?.comboID,
        comboGroupId,
        products: [
          { ...(memberships.find(membership => membership.productId === selectedMembership) || {}), quantity: 1 },
        ],
        isPerResource: productDetails.isPerResource,
        custIds: customers.map(customer => customer.custId),
      };

      dispatch(addNonschedulableProductsToCart({ ...payload }));
    } else {
      const membershipProducts = customers.map((item): any => {
        return {
          custId: item.custId,
          productId: selectedMembership,
          quantity: 1,
        };
      });
      payload = {
        isAddItemWithMembership: true,
        isSchedulable: product.isSchedulable,
        checkDetails: [
          ...membershipProducts,
          ...product.items.map((item): any => {
            return { ...item, ...{ membershipProductId: selectedMembership } };
          }),
        ],
        productType: product.productType,
      };

      dispatch(addItemToCartAction({ ...payload }));
    }

    dispatch(membershipResetAction());

    if (!!areOnlyNonschedulableProducts && areOnlyNonschedulableProducts()) {
      history.replace(ROUTES.PRODUCTS);
    }
  };

  const [selectedMembership, setSelectedMembership] = useState(0);
  const [quickProductViewActive, setQuickProductViewActive] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleMembershipClick = (item: any) => {
    setCurrentProduct(item);
    setSelectedMembership(item.productId);
    setQuickProductViewActive(true);
  };

  const closeQuickProductView = () => {
    setCurrentProduct(null);
    setSelectedMembership(0);
    setQuickProductViewActive(false);
  };

  const handleBack = () => {
    if (chooseParticipant) {
      dispatch(membershipResetAction());
      // chooseParticipant();
    }
    if (!!handleBackButton) {
      handleBackButton();
    }
  };

  return (
    <>
      {memberships?.length ? (
        <div className={styles.productMembershipRequired}>
          <HeaderPanel backButton={!hideBackButton} handleBackClick={handleBack} logOutButton border />
          <div className={styles.productMembershipRequired__header}>
            <div className={styles.productMembershipRequired__headerAlert}>This product requires a membership</div>
            <div className={styles.productMembershipRequired__headerSub}>
              <div className={styles.guests__text}>
                The following guests <strong>don't have a required membership:</strong>
              </div>
              {customers &&
                customers.map(customer => (
                  <div key={customer.custId} className={styles.guests__list}>
                    <Avatar custId={customer.custId} />
                    <div className={styles.guests__listName}>
                      {customer.fName} {customer.lName}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.productMembershipRequired__main}>
            <div className={styles.productMembershipRequired__mainTitle}>
              Please choose the membership you’d like to purchase to buy this product
            </div>
            <div className={styles.productMembershipRequired__list}>
              {memberships.map((item, idx): any => (
                <ProductItem
                  key={idx}
                  cover={item.largeIcon}
                  highResolutionCover={item.highResImagePath}
                  title={item.description}
                  handleClick={() => handleMembershipClick(item)}
                  isRequiredMembership={item.isRequiredMembership}
                  isActive={selectedMembership === item.productId}
                  price={item.price1}
                />
              ))}
            </div>
          </div>
          {!hideCancelButton && (
            <div className={styles.productMembershipRequired__navigation}>
              <NavigationPanel
                onLeftButtonClick={closeProductMembershipRequired}
                leftButtonTitle="Cancel"
                customClass={styles.navigationPanel}
              />
            </div>
          )}
        </div>
      ) : (
        <WarningAlert
          isOpen={!memberships.length}
          title="Sorry!"
          description="You need a membership to purchase this product. Please add a membership to your cart and try again."
          handleClick={() => closeProductMembershipRequired()}
        />
      )}
      {quickProductViewActive && (
        <QuickProductView
          quantityIsHide={true}
          disabledAddToCart={true}
          setQuickProductViewActive={setQuickProductViewActive}
          handleAddToCart={handleAddToCart}
          isOpen={quickProductViewActive}
          productDetail={currentProduct}
          cancelHandler={closeQuickProductView}
        />
      )}
    </>
  );
};

export default ProductMembershipRequired;
