import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as Api from '../../api';
import { history } from '../../App';
import { COUNTRIES, GENDER, RELATIONSHIP_TYPES, ROUTES } from '../../constants';
import { addToGuestsList } from '../../store/Customer/actions';

import detectLabelField from '../../utils/detectLabelField';
import detectRequiredField from '../../utils/detectRequiredField';
import isAdult from '../../utils/isAdult';
import validator from '../../utils/validator';

import HeaderPanel from '../../components/HeaderPanel';
import WizardFormStep from '../../components/WizardFormStep';
import Modal from '../../components/Modal';
import WarningAlert from '../../components/WarningAlert';
import Button from '../../components/Button';
import moment from 'moment';
import styles from './styles.module.scss';
import ContentContainer from '../../components/ContentContainer';
import STATES from '../../constants/states';
import { findLastVisitedGuest } from '../../utils/core';

interface Props {
  endAddingGuestProcessHandler?: () => void;
}

type formType = {
  [key: string]: any;
};

const NewGuestScreen = ({ endAddingGuestProcessHandler }: Props) => {
  const dispatch = useDispatch();
  const {
    newCustomerStore,
    registrationSettings: {
      adultRegistrationFields,
      minorRegistrationFields,
      newAdultRegistrationSteps,
      newMinorRegistrationSteps,
      adultAge,
      defaultCountry,
      allowDuplicateEmail,
      enableRelationshipScreen,
    },
    source: sourceStore,
  } = useSelector(state => state);
  const [registrationSteps, setRegistrationSteps] = useState(newAdultRegistrationSteps);
  const [registrationFields, setRegistrationFields] = useState(adultRegistrationFields);
  const [doNotMail, setDoNotMail] = useState(true);

  const [activeField, setActiveField] = useState(registrationSteps[0]);
  const [fieldsOfForm, setFieldsOfForm] = useState<formType>({});
  const [relationships, setRelationships] = useState('');
  const [errorsOfField, setErrorsOfField] = useState('');
  const [emailAddressIsUnavailable, setEmailAddressIsUnavailable] = useState(false);
  const [foundProfilePopup, setFoundProfilePopup] = useState(false);
  const [customersFound, setCustomersFound] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (defaultCountry) {
      setFieldsOfForm({ ...fieldsOfForm, country: defaultCountry });
    }
  }, []);

  const onChangeField = async (fieldName: string, newFieldValue: string) => {
    try {
      setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
      setErrorsOfField('');
      if (fieldName === 'fName' || fieldName === 'lName') {
        await validator(
          activeField,
          newFieldValue,
          detectRequiredField(registrationFields, activeField),
          allowDuplicateEmail,
        );
      }
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const onSelectHandler = async (fieldName: string, newFieldValue: string) => {
    if (fieldsOfForm[fieldName] === newFieldValue) {
      await onChangeField(fieldName, '');
    } else {
      await onChangeField(fieldName, newFieldValue);
    }
  };

  const onChangeRelationships = (fieldName: string, newFieldValue: string) => {
    if (relationships === newFieldValue) {
      setRelationships('');
    } else {
      setRelationships(newFieldValue);
    }
  };

  const findCustomer = async () => {
    const { data: responseData } = await Api.findCustomerByNameAndDOB(
      fieldsOfForm.fName,
      fieldsOfForm.lName,
      fieldsOfForm.birthDate,
    );
    responseData.birthDate = moment(responseData.birthDate).format('MM/DD/YYYY');
    return responseData;
  };

  const updateDoNotMail = () => {
    setDoNotMail(prevState => !prevState);
  };

  const goToNextStep = async () => {
    try {
      if (activeField === 'customerImage') {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 3000));
        setLoading(false);
      }

      await validator(
        activeField,
        fieldsOfForm[activeField],
        detectRequiredField(registrationFields, activeField),
        allowDuplicateEmail,
      );

      if (_.last(registrationSteps) === activeField) {
        return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
      }
      if (activeField === 'birthDate') {
        if (!isAdult(fieldsOfForm.birthDate, adultAge)) {
          setRegistrationSteps(newMinorRegistrationSteps);
          setRegistrationFields(minorRegistrationFields);
        } else {
          setRegistrationSteps(newAdultRegistrationSteps);
          setRegistrationFields(adultRegistrationFields);
        }
      }

      if (activeField === 'lName') {
        const response = await findCustomer();
        if (response.length === 1) {
          setFoundProfilePopup(true);
          setCustomersFound(response[0]);
          return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
        } else if (response.length > 1) {
          const lastVisitedGuest = findLastVisitedGuest(response);
          setCustomersFound([lastVisitedGuest]);
          return enableRelationshipScreen ? setActiveField('relationships') : goToAfterRelationships();
        }
      }

      const indexOfCurrentField = registrationSteps.indexOf(activeField);
      return setActiveField(registrationSteps[indexOfCurrentField + 1]);
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToAfterRelationships = async () => {
    let guestData = {};
    try {
      if (enableRelationshipScreen) {
        await validator(activeField, relationships, true, allowDuplicateEmail);
      }
      if (customersFound) {
        guestData = {
          relationships,
          custId: customersFound.custId,
          commonData: {
            birthDate: customersFound.birthDate,
            fName: customersFound.fName,
            lName: customersFound.lName,
            gender: customersFound.gender,
          },
        };
        dispatch(addToGuestsList(guestData));
        history.push(ROUTES.REVIEW_OF_NEW_CUSTOMERS);
        return;
      }
      guestData = {
        relationships,
        id: `f${(~~(Math.random() * 1e8)).toString(16)}`,
        commonData: { ...fieldsOfForm, doNotMail },
      };
      dispatch(addToGuestsList(guestData));
      history.push(ROUTES.REVIEW_OF_NEW_CUSTOMERS);
      return;
    } catch (e) {
      setErrorsOfField(e.message);
    }
  };

  const goToPrevStep = () => {
    if (activeField === 'birthDate') {
      history.replace(ROUTES.REVIEW_OF_NEW_CUSTOMERS);
      return;
    }
    if (activeField === 'relationships') {
      setActiveField(_.last(registrationSteps));
      return setErrorsOfField('');
    }

    const indexOfCurrentField = registrationSteps.indexOf(activeField);
    if (indexOfCurrentField <= 0) return;
    setActiveField(registrationSteps[indexOfCurrentField - 1]);
    setErrorsOfField('');
  };

  return (
    <Fragment>
      <ContentContainer loading={loading} bgColor="#FFFFFF">
        <HeaderPanel startOverButton border />
        <WizardFormStep
          type="numeric"
          fieldName="birthDate"
          value={fieldsOfForm['birthDate'] || ''}
          label={detectLabelField(registrationFields, 'birthDate') + ' MM/DD/YYYY'}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'birthDate' === activeField}
          required={detectRequiredField(registrationFields, 'birthDate')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="fName"
          value={fieldsOfForm['fName']}
          label={detectLabelField(registrationFields, 'fName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'fName' === activeField}
          required={detectRequiredField(registrationFields, 'fName')}
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
        />
        <WizardFormStep
          type="text"
          fieldName="lName"
          value={fieldsOfForm['lName']}
          label={detectLabelField(registrationFields, 'lName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'lName' === activeField}
          required={detectRequiredField(registrationFields, 'lName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="email"
          fieldName="emailAddress"
          value={fieldsOfForm['emailAddress']}
          label={detectLabelField(adultRegistrationFields, 'emailAddress')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'emailAddress' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'emailAddress')}
          doNotMail={!doNotMail}
          setDoNotMail={updateDoNotMail}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="cell"
          value={fieldsOfForm['cell']}
          label={detectLabelField(registrationFields, 'cell')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'cell' === activeField}
          required={detectRequiredField(registrationFields, 'cell')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="radio"
          fieldName="gender"
          value={fieldsOfForm['gender']}
          label={detectLabelField(registrationFields, 'gender')}
          options={GENDER}
          valueField="value"
          displayField="title"
          onChange={onSelectHandler}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'gender' === activeField}
          required={detectRequiredField(registrationFields, 'gender')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="racerName"
          value={fieldsOfForm['racerName']}
          label={detectLabelField(registrationFields, 'racerName')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'racerName' === activeField}
          required={detectRequiredField(registrationFields, 'racerName')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address"
          value={fieldsOfForm['address']}
          label={detectLabelField(registrationFields, 'address')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address' === activeField}
          required={detectRequiredField(registrationFields, 'address')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="address2"
          value={fieldsOfForm['address2']}
          label={detectLabelField(registrationFields, 'address2')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'address2' === activeField}
          required={detectRequiredField(registrationFields, 'address2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="city"
          value={fieldsOfForm['city']}
          label={detectLabelField(registrationFields, 'city')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'city' === activeField}
          required={detectRequiredField(registrationFields, 'city')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type={fieldsOfForm['country'] === 'United States' ? 'typeSelector' : 'text'}
          fieldName="state"
          value={fieldsOfForm['state']}
          options={STATES}
          displayField={detectLabelField(registrationFields, 'state')}
          label={detectLabelField(registrationFields, 'state')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'state' === activeField}
          required={detectRequiredField(registrationFields, 'state')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="typeSelector"
          fieldName="country"
          value={fieldsOfForm['country']}
          options={COUNTRIES}
          displayField={detectLabelField(adultRegistrationFields, 'country')}
          label={detectLabelField(adultRegistrationFields, 'country')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'country' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'country')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
          keyboardLayout={'country'}
        />
        <WizardFormStep
          type="text"
          fieldName="zip"
          value={fieldsOfForm['zip']}
          label={detectLabelField(registrationFields, 'zip')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'zip' === activeField}
          required={detectRequiredField(registrationFields, 'zip')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="picture"
          fieldName="customerImage"
          value={fieldsOfForm['customerImage']}
          label="Picture"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'customerImage' === activeField}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="text"
          fieldName="licenseNumber"
          value={fieldsOfForm['licenseNumber']}
          label={detectLabelField(adultRegistrationFields, 'licenseNumber')}
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'licenseNumber' === activeField}
          required={detectRequiredField(adultRegistrationFields, 'licenseNumber')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom1"
          value={fieldsOfForm['custom1']}
          label={detectLabelField(registrationFields, 'custom1')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom1' === activeField}
          required={detectRequiredField(registrationFields, 'custom1')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom2"
          value={fieldsOfForm['custom2']}
          label={detectLabelField(registrationFields, 'custom2')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom2' === activeField}
          required={detectRequiredField(registrationFields, 'custom2')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom3"
          value={fieldsOfForm['custom3']}
          label={detectLabelField(registrationFields, 'custom3')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom3' === activeField}
          required={detectRequiredField(registrationFields, 'custom3')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="checkbox"
          fieldName="custom4"
          value={fieldsOfForm['custom4']}
          label={detectLabelField(registrationFields, 'custom4')}
          onChange={onChangeField}
          errorMessage={errorsOfField}
          isActive={'custom4' === activeField}
          required={detectRequiredField(registrationFields, 'custom4')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="itemSelect"
          fieldName="relationships"
          value={relationships}
          options={RELATIONSHIP_TYPES}
          valueField="value"
          displayField="label"
          label={`What is ${fieldsOfForm?.fName}'s relationship to ${newCustomerStore.commonData.fName}`}
          onChange={onChangeRelationships}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'relationships' === activeField}
          required={true}
          goToNextStep={() => goToAfterRelationships()}
          goToPrevStep={() => goToPrevStep()}
        />
        <WizardFormStep
          type="itemSelect"
          fieldName="sourceId"
          value={fieldsOfForm['sourceId']}
          label={detectLabelField(registrationFields, 'sourceId')}
          options={sourceStore.data}
          valueField="sourceId"
          displayField="sourceName"
          onChange={onChangeField}
          placeholder={''}
          errorMessage={errorsOfField}
          isActive={'sourceId' === activeField}
          required={detectRequiredField(registrationFields, 'sourceId')}
          goToNextStep={() => goToNextStep()}
          goToPrevStep={() => goToPrevStep()}
        />
        <Modal isOpen={emailAddressIsUnavailable}>
          <div className={styles.warningAlert}>
            <h2 className={styles.warningAlertTitle}>Whoops!</h2>
            <h3 className={styles.warningAlertSubTitle}>
              This email address is taken.
              <br />
              Please use another email address,
              <br />
              or if you believe you’ve reached this message by mistake,
              <br />
              log out and see a cashier for help.
            </h3>
            <div className={styles.warningAlertFooter}>
              <Button
                theme="red"
                size="base"
                customClass={styles.warningAlertButton}
                handleClick={() => setEmailAddressIsUnavailable(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        </Modal>
        <WarningAlert
          isOpen={foundProfilePopup}
          title="Profile Found!"
          description={'The account has been located!'}
          handleClick={() => setFoundProfilePopup(false)}
        />
      </ContentContainer>
    </Fragment>
  );
};

export default NewGuestScreen;
