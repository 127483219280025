import {
  CREATE_CART_TYPES,
  ADD_ITEM_TO_CART_TYPES,
  ADD_COMBO_ITEM_TO_CART_TYPES,
  REMOVE_ITEM_FROM_CART_TYPES,
  REMOVE_CART_TYPES,
} from '../actions';
import { NOT_ENOUGH_TIME_SLOTS } from '../../PurchaseFlow/actions';
import { END_CUSTOMER_SESSION } from '../../General/actions';
import { CartData } from 'typings/cart';

export const initialState = {
  checkId: null,
  custId: null,
  checkType: 1,
  checkStatus: 0,
  checkName: '',
  userId: null,
  checkTotalApplied: null,
  brokerName: '',
  notes: null,
  gratuity: 0.0,
  fee: 0.0,
  openedDate: '',
  closedDate: null,
  isTaxExemp: false,
  discount: 0.0,
  checkDiscountId: 0,
  checkDiscountNotes: '',
  checkDiscountUserId: 0,
  checkSubtotal: 0.0,
  checkTax: 0.0,
  checkGST: 0.0,
  checkPST: 0.0,
  checkTotal: 0.0,
  checkPaidTax: 0.0,
  checkPaidTotal: 0.0,
  checkRemainingTax: 0.0,
  checkRemainingTotal: 0.0,
  checkDetails: [],
  comboCheckDetails: [],
  comboCheckDetailsPerResource: [],
  loading: false,
  removeLineItems: false,
  isRequiredMembership: false,
  membershipProducts: null,
  checkKioskFees: 0.0,
  checkVenueKioskFeeTotal: 0.0,
  checkTaxesAndKioskFees: 0.0,
  checkTotalPlusKioskFees: 0.0,
  checkClubSpeedKioskFeeTotal: 0.0,
};

export type InitialStateType = typeof initialState;

export default function cartReducer(state = initialState, { type, payload }): CartData {
  switch (type) {
    case CREATE_CART_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CART_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case CREATE_CART_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case CREATE_CART_TYPES.RESET_LOADING:
      return {
        ...state,
        loading: false,
      };

    case ADD_ITEM_TO_CART_TYPES.NONSCHEDULABLE:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEM_TO_CART_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADD_ITEM_TO_CART_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
        selectionOfParticipant: false,
        notEnoughTimeSlots: false,
        loading: false,
      };
    case ADD_ITEM_TO_CART_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
        ...payload,
      };

    case ADD_COMBO_ITEM_TO_CART_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
        selectionOfParticipant: false,
        notEnoughTimeSlots: false,
        loading: false,
      };

    case NOT_ENOUGH_TIME_SLOTS:
      return {
        ...state,
        loading: false,
      };

    case REMOVE_ITEM_FROM_CART_TYPES.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_ITEM_FROM_CART_TYPES.SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case REMOVE_ITEM_FROM_CART_TYPES.FAILURE:
      return {
        ...state,
        loading: false,
      };

    case REMOVE_CART_TYPES.SUCCESS:
      return {
        ...initialState,
      };
    case REMOVE_CART_TYPES.FAILURE:
      return {
        ...initialState,
      };
    case REMOVE_CART_TYPES.REQUEST:
      return {
        ...state,
        ...payload,
        loading: true,
      };
    case END_CUSTOMER_SESSION: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}
