import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActivityGuestType,
  ActivityType,
  SelectedGuestsForActitivies,
} from '../../../store/Customer/types/assignActivities';
import { getCustomerGroupAction } from '../../../store/CustomerGroup/actions';
import MemberCard from '../../MemberCard';
import Confirm from '../../../components/Prompt';
import * as Api from '../../../api';
import styles from './styles.module.scss';
import classNames from 'classnames';
import moment from 'moment';

const SelectActivitiesGuests = ({ data, activitiesGuest, setActivitiesGuests, setGuestRemovePending }: PropsTypes) => {
  const dispatch = useDispatch();
  const [memberForRemoving, setMemberForRemoving] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const currentUser = useSelector(state => state.customerStore);
  const guests = useSelector(state => state.customerGroup);
  const { assignActivitiesReducer } = useSelector(state => state);

  // if user is already selected and saved in db can't be unselected
  const isActive = (custId: number) => !!activitiesGuest?.[data.activityId]?.find(x => x.custId === custId);
  const isActivityFull = () => activitiesGuest[data.activityId].length === data.maxGuests;

  const selectGuest = (guest: ActivityGuestType) => {
    const newActivitiesGuest = { ...activitiesGuest };
    const isSelected = !!newActivitiesGuest[data.activityId].find(el => el.custId === guest.custId);
    if (isSelected) {
      newActivitiesGuest[data.activityId] = newActivitiesGuest[data.activityId].filter(
        el => el.custId !== guest.custId,
      );
    }
    if (!isSelected && !isActivityFull()) {
      newActivitiesGuest[data.activityId] = [...newActivitiesGuest[data.activityId], guest];
    }
    setActivitiesGuests({ ...newActivitiesGuest });
  };

  const removeFromGroup = custId => {
    setMemberForRemoving(custId);
    setShowPrompt(true);
  };

  const confirmRemoveFromGroup = async () => {
    try {
      setShowPrompt(false);
      setGuestRemovePending(true);
      await Api.removeFromCustomerGroup({
        custIds: [memberForRemoving],
        createdById: currentUser.custId,
      });
      await dispatch(getCustomerGroupAction({ custId: currentUser.custId }));
      setGuestRemovePending(false);
    } catch (e) {
      setShowPrompt(false);
      setGuestRemovePending(false);
    }
  };

  const cancelRemoveFromGroup = () => {
    setMemberForRemoving(null);
    setShowPrompt(false);
  };
  const disableGuestSelection = (custId: number, activityId: number, name: string) => {
    if (!isActive(custId) && isActivityFull()) {
      return 'Maximum Participants Selected';
    }

    if (assignActivitiesReducer?.selectedGuestsForActitivies?.length > 0) {
      const getCurrentActivityGuests: SelectedGuestsForActitivies = assignActivitiesReducer.selectedGuestsForActitivies.find(
        activity => activity.activityId == activityId,
      );
      const getCurrentGuest = getCurrentActivityGuests.guestActivityRegistrationDictionary.find(
        member => member.custId === custId,
      );

      if (getCurrentGuest.isRegistered) {
        return 'The Guest Is Already Registered';
      }

      if (getCurrentGuest.isRegisteredOnAnotherActivity) {
        return `${name} is participating in another activity at this time`;
      }
    }
  };

  return data ? (
    <div className={styles.assignActivities}>
      <div className={styles.imgBox}>
        <img src={data.activityImage} alt="" className={styles.activityImg} />
        <p className={styles.activityTitle}>{data.activityName}</p>
        <p className={styles.activityStartTime}>{moment(data.startTime).format('hh:mm A')}</p>
      </div>
      <h1 className={styles.title}>WHO IS PARTICIPATING IN THIS ACTIVITY?</h1>
      <p className={styles.desc}>Select up to {data.maxGuests} participants and continue</p>
      <div
        className={classNames(
          styles.guests,
          data.maxGuests === activitiesGuest?.[data.activityId]?.length && styles.filled,
        )}
      >
        {currentUser && (
          <MemberCard
            handleClick={() =>
              selectGuest({
                custId: currentUser.custId,
                fName: currentUser.fName,
                lName: currentUser.lName,
                status1: currentUser.status1,
                birthDate: currentUser.birthDate,
              })
            }
            isActive={isActive(currentUser.custId)}
            custId={currentUser.custId}
            birthday={currentUser.birthDate}
            firstName={currentUser.fName}
            lastName={currentUser.lName}
            error={disableGuestSelection(currentUser.custId, data.activityId, currentUser.fName)}
          />
        )}
        {guests?.members &&
          [...guests.members.map(el => ({ ...el }))].map(el => (
            <MemberCard
              isPrimaryMember={false}
              removeHandler={() => removeFromGroup(el.custId)}
              key={el.custId + data.activityId}
              custId={el.custId}
              firstName={el.fName}
              lastName={el.lName}
              birthday={el.birthDate}
              isActive={isActive(el.custId)}
              error={disableGuestSelection(el.custId, data.activityId, el.fName)}
              handleClick={() =>
                selectGuest({
                  custId: el.custId,
                  fName: el.fName,
                  lName: el.lName,
                  status1: el?.status1 || 0,
                  birthDate: el.birthDate,
                })
              }
            />
          ))}
      </div>
      <Confirm
        isOpen={showPrompt}
        title="Are you sure?"
        titleButtonCancel="Cancel"
        titleButtonOk="Continue"
        handleReturnClick={() => cancelRemoveFromGroup()}
        handleContinueClick={() => confirmRemoveFromGroup()}
      >
        <div className={styles.confirmRemoveFromGroup}>
          <p>
            Are you sure want to remove this
            <br />
            person from your group?
          </p>
        </div>
      </Confirm>
    </div>
  ) : null;
};

export default SelectActivitiesGuests;

interface PropsTypes {
  data: ActivityType;
  activitiesGuest: { [key: number]: ActivityGuestType[] };
  setActivitiesGuests: Dispatch<SetStateAction<{ [key: number]: ActivityGuestType[] }>>;
  setGuestRemovePending: Dispatch<SetStateAction<boolean>>;
}
