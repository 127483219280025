import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import blackCrossIcons from 'assets/icons/black-cross.svg';
import { removeItemFromCartAction } from 'store/Cart/actions';
import CartItem from 'components/Cart/CartItem';
import styles from './styles.module.scss';
import { CheckComboProductCart } from 'typings/cart';
import { Member } from 'typings/product';
import cn from 'classnames';
import Confirm from 'components/Prompt/Prompt';

interface CartItemProps {
  items: CheckComboProductCart;
  members: Member[];
  comboId?: string;
  combosQuantity: any[];
  activeComboGroupId?: number;
}

const ComboCartItemPerPerson = ({ items, members, comboId, combosQuantity, activeComboGroupId }: CartItemProps) => {
  const dispatch = useDispatch();

  const {
    comboProducts: { combos },
    cartStore: { comboCheckDetails },
  } = useSelector(state => state);
  const [showLimitationsMessage, setShowLimitationsMessage] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  function isSchedulableCombo() {
    combosQuantity.some(combo => {
      const findSchedulableProduct = combo.checkDetails.find(item => item.isSchedulable);
      if (findSchedulableProduct) {
        return false;
      }
    });

    return true;
  }

  const combInfo = combos.find(combo => combo.categoryID === items.comboId);

  const removeComboItem = items => {
    if (isSchedulableCombo() && !items.comboForCustId) {
      setShowWarningMessage(true);
      return;
    } else if (combosQuantity.length <= combInfo.minimumGuests && combosQuantity.length !== 1) {
      setShowLimitationsMessage(true);
      return;
    }

    dispatch(
      removeItemFromCartAction({
        isComboProduct: true,
        custIds: [items.comboForCustId],
        comboId: items.comboId,
        min: combInfo.minimumGuests,
        max: combInfo.maximumGuests,
        comboGroupId: items.comboGroupId,
      }),
    );
  };

  function disableRemoveCombosButton() {
    if (!comboId && !members?.length) {
      return false;
    }
    if (comboId === String(items.comboId) && activeComboGroupId === items.comboGroupId) {
      return true;
    }

    return false;
  }

  return (
    <div>
      <div className={styles.comboCartItemsHeader}>
        <h3 className={styles.comboName}>{combInfo?.description}</h3>
        <h3 className={styles.comboName}>${items?.comboPrice}</h3>
      </div>
      <div className={styles.comboCartItemsWrapper}>
        <div className={styles.comboCartItemsInfoWrapper}>
          {items.checkDetails.map(item => (
            <CartItem
              key={item.checkDetailId}
              currentCartItem={item}
              checkDetailId={item.checkDetailId}
              productId={item.productId}
              productType={item.productType}
              hideRemoveButton={true}
              hideProductTitle={item.isSchedulable}
              hidePrice={true}
              isCombo={true}
              custId={items?.comboForCustId}
            />
          ))}
        </div>
        <div>
          <button
            disabled={disableRemoveCombosButton()}
            className={cn(
              styles.cartItemsDelete,
              disableRemoveCombosButton() ? styles.cartItemsDeleteDisabled : styles.cartItemsDeleteActive,
            )}
            onClick={() => removeComboItem(items)}
          >
            <img src={blackCrossIcons} alt="remove" />
          </button>
        </div>
      </div>
      <Confirm
        isOpen={showLimitationsMessage || showWarningMessage}
        title="Warning"
        titleButtonOk="OK"
        titleButtonCancel="Cancel"
        handleContinueClick={() => {
          setShowLimitationsMessage(false);
          setShowWarningMessage(false);
          const guestsCustIds =
            comboCheckDetails.filter(combo => combo.comboId === items.comboId)?.map(item => item?.comboForCustId) || [];

          dispatch(
            removeItemFromCartAction({
              isComboProduct: true,
              comboId: items.comboId,
              custIds: guestsCustIds,
              min: combInfo.minimumGuests,
              max: combInfo.maximumGuests,
              comboGroupId: items.comboGroupId,
            }),
          );
        }}
        handleReturnClick={() => {
          setShowWarningMessage(false);
          setShowLimitationsMessage(false);
        }}
      >
        <div className={styles.guestLimitationsWarningModalWrapper}>
          <div>
            {showWarningMessage ? (
              <p>Are you sure? This will remove the entire combo from your cart.</p>
            ) : (
              <p>
                You cannot delete this combo because the minimum number of guests for this combo is{' '}
                {combInfo?.minimumGuests}. Are you sure you want to delete this combo for all guests?
              </p>
            )}
          </div>
        </div>
      </Confirm>
    </div>
  );
};

export default ComboCartItemPerPerson;
