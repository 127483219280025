import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { history } from '../../App';
import { PRODUCT_TYPE, ROUTES } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsByCategoryAction } from '../../store/ProductsByCategory/actions';

import { useGridHeightHook } from '../../hooks';

import ChooseParticipant from '../../components/ChooseParticipant';
import ContentContainer from '../../components/ContentContainer';
import IdleMonitor from '../../services/IdleMonitor';
import HeaderPanel from '../../components/HeaderPanel';
import AddGuestForm from '../../components/AddGuestForm';

import ProductGrid from '../../components/ProductGrid';
import ProductGridItem from '../../components/ProductGridItem';
import QuickProductView from '../../components/QuickProductView';
import QuickGiftCardProductView from '../../components/QuickGiftCardProductView';

import Cart from '../../components/Cart';
import styles from './styles.module.scss';
import { addItemToCartAction } from '../../store/Cart/actions';
import Title from '../../components/Title';
import ProductMembershipRequired from '../../components/ProductMembershipRequired';

import * as Api from '../../api';
import update from 'immutability-helper';
import Prompts from '../../components/Prompts';
import Warning from '../../components/Warning';
import { endCustomerSessionAction } from '../../store/General/actions';
import { getComboAction } from '../../store/ProductCategories/actions';
import { comboCategory } from '../../config/combos';
import { getItemsApiCredentials } from 'utils/core';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProductsScreen = ({ location }) => {
  // @ts-ignore
  const { categoryId } = useParams();
  const query = useQuery();
  const dispatch = useDispatch();

  const [showAddGuest, setShowAddGuest] = React.useState(false);

  const [currentProduct, setcurrentProduct] = React.useState({});
  const [quickProductViewActive, setQuickProductViewActive] = React.useState(false);
  const [quickGiftCardProductViewActive, setQuickGiftCardProductViewActive] = React.useState(false);
  const [chooseParticipantState, setChooseParticipantState] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [quickViewQuantityIsHide, setQuickViewQuantityIsHide] = React.useState(false);
  const {
    customerStore,
    productsByCategoryStore,
    customerGroup: customerGroupState,
    membershipStore,
    comboProducts,
  } = useSelector(state => state);

  const [prompts, setPrompts] = React.useState(null);
  const [qty, setQty] = React.useState(0);
  const [promptTempMembers, setPromptTempMembers] = React.useState(null);
  const [promptPayload, setPromptPayload] = React.useState(null);

  const greedContainerHeight = useGridHeightHook();

  let products;
  if (categoryId === comboCategory.id) {
    products = comboProducts.combos;
  } else {
    products = productsByCategoryStore.productsByCategory.filter((item: any) => {
      return item.categoryId == categoryId;
    });
  }

  const [productDetail, setProductDetail] = React.useState({
    productId: null,
    title: '',
    description: '',
    largeIcon: '',
    highResImagePath: '',
    price1: 0,
    productType: 0,
  });

  React.useEffect(() => {
    setLoading(true);
    if (categoryId === comboCategory.id) {
      if (!comboProducts?.combos?.length) {
        const credetials = getItemsApiCredentials();
        dispatch(getComboAction(credetials));
        products = comboProducts.combos;
      }
    } else {
      if (!products.length) {
        dispatch(getProductsByCategoryAction({ categoryId, categoryType: query.get('type') }));
      }
    }
    setLoading(false);
  }, []);

  const cancelClickHandler = () => {
    setChooseParticipantState(false);
  };

  const productItemClickHandler = async (product: any) => {
    if (categoryId === comboCategory.id) {
      try {
        setQuickViewQuantityIsHide(true);
        setcurrentProduct({
          ...product,
        });
        setQuickProductViewActive(true);
        return;
      } catch (e) {
        console.log(e);
      }
    }

    switch (product.productType) {
      case PRODUCT_TYPE.MEMBERSHIP:
        if (!product.isSchedulable) {
          setQuickViewQuantityIsHide(true);
          setProductDetail(product);
          setcurrentProduct(product);
          setQuickProductViewActive(true);
        } else {
          history.replace(`${ROUTES.PRODUCTS}/${categoryId}/${product.productId}`);
        }
        break;
      case PRODUCT_TYPE.POINT:
        history.replace(`${ROUTES.PRODUCTS}/${categoryId}/${product.productId}`);
        break;
      case PRODUCT_TYPE.FOOD:
        setcurrentProduct(product);
        setQuickProductViewActive(true);
        break;
      case PRODUCT_TYPE.GAME_CARD:
      case PRODUCT_TYPE.GIFT_CARD:
        setcurrentProduct(product);
        setQuickGiftCardProductViewActive(true);
        break;
      default:
        setcurrentProduct(product);
        setQuickProductViewActive(true);
    }
  };

  const setPromptsData = ({ quantity, promptData, members = null, payload }: any) => {
    setQty(quantity);
    if (members) {
      setPromptTempMembers(members);
    }
    if (payload) {
      setPromptPayload(payload);
    }
    const prompts = promptData.map((prompt, idx): any => {
      const labels = prompt.promptLabels.map((label): any => {
        return {
          ...label,
          checked: false,
        };
      });
      return {
        ...prompt,
        isOpen: idx === 0,
        promptLabels: labels,
        disabledContinue: labels && labels.length,
      };
    });
    setPrompts(prompts);
  };

  const addToCart = payload => {
    setChooseParticipantState(false);
    dispatch(addItemToCartAction({ ...payload }));
    history.replace(ROUTES.PRODUCTS);
  };

  const closeQuickProductView = () => {
    setQuickProductViewActive(false);
    setQuickViewQuantityIsHide(false);
  };
  const closeQuickGiftCardProductView = () => {
    setQuickGiftCardProductViewActive(false);
  };

  const doneClickHandler = async members => {
    try {
      setLoading(true);
      const {
        data: { prompts },
      } = await Api.fetchProductPrompts(productDetail.productId);
      const arr = [];
      members.map((item: any) => {
        const itemData = {
          productId: productDetail.productId,
          activityId: null,
          scheduleTime: null,
          custId: item.custId,
          quantity: 1,
          data: {
            cover: productDetail.highResImagePath
              ? productDetail.highResImagePath
              : `data:image/jpeg;base64,${productDetail.largeIcon}`,
          },
        };
        arr.push(itemData);
      });
      if (!prompts.length) {
        await addToCart({
          productType: productDetail.productType,
          checkDetails: [...arr],
        });
      } else {
        await setPromptsData({
          members,
          promptData: prompts,
          quantity: 1,
          payload: {
            productType: productDetail.productType,
            checkDetails: [...arr],
          },
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const endCustomerSession = () => {
    dispatch(endCustomerSessionAction());
  };

  return (
    <>
      <ContentContainer loading={loading || productsByCategoryStore.loading || comboProducts.loading}>
        <HeaderPanel backButton handleBackClick={() => history.goBack()} logOutButton border />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <Title message={location?.state?.title} />
              <div className={styles.productsList} style={{ height: `${greedContainerHeight}px` }}>
                {products && products.length > 0 && (
                  <ProductGrid>
                    {products &&
                      products.map((item: any) => (
                        <ProductGridItem
                          key={item.productId}
                          cover={item.largeIcon}
                          highResolutionCover={item.highResImagePath}
                          title={item.description}
                          handleClick={() => productItemClickHandler(item)}
                          isRequiredMembership={item.isRequiredMembership}
                          price={item}
                        />
                      ))}
                  </ProductGrid>
                )}
                {!loading && !productsByCategoryStore.loading && !comboProducts.loading && !products?.length && (
                  <Warning
                    title="Oh no!"
                    text="There are no products available for purchase right now, please check again later."
                    buttonText="OK"
                    handleClick={endCustomerSession}
                  />
                )}
              </div>
            </div>
          </div>
          {quickProductViewActive && (
            <QuickProductView
              quantityIsHide={quickViewQuantityIsHide}
              isOpen={quickProductViewActive}
              productDetail={currentProduct}
              cancelHandler={closeQuickProductView}
              disabledAddToCart={quickViewQuantityIsHide}
              setQuickProductViewActive={setQuickProductViewActive}
              setChooseParticipantState={setChooseParticipantState}
              setLoading={setLoading}
              setPromptsData={setPromptsData}
            />
          )}
          <QuickGiftCardProductView
            isOpen={quickGiftCardProductViewActive}
            productDetail={currentProduct}
            setPromptsData={setPromptsData}
            cancelHandler={closeQuickGiftCardProductView}
          />

          <Cart />
          {showAddGuest && <AddGuestForm endAddingGuestProcessHandler={() => setShowAddGuest(false)} />}
        </div>
      </ContentContainer>

      {chooseParticipantState && (
        <ChooseParticipant
          isOpen={chooseParticipantState}
          members={[customerStore, ...customerGroupState.members]}
          cancelClickHandler={cancelClickHandler}
          addGuestHandler={() => setShowAddGuest(true)}
          doneClickHandler={doneClickHandler}
        />
      )}
      {membershipStore.customers && (
        <ProductMembershipRequired
          closeChooseParticipant={() => setChooseParticipantState(false)}
          chooseParticipant={() => setChooseParticipantState(true)}
        />
      )}

      {prompts && (
        <Prompts
          setQty={setQty}
          setPromptTempMembers={setPromptTempMembers}
          setPrompts={setPrompts}
          handleContinueClick={async idx => {
            const uncheckedPrompts = prompts.findIndex(item => item.disabledContinue);
            if (uncheckedPrompts !== -1) {
              const newPromptsData = update(prompts, {
                [idx]: { isOpen: { $set: false } },
                [uncheckedPrompts]: { isOpen: { $set: true } },
              });
              setPrompts(newPromptsData);
            } else {
              setPrompts(null);
              await addToCart(promptPayload);
            }
          }}
          prompts={prompts}
        />
      )}

      <IdleMonitor />
    </>
  );
};

export default ProductsScreen;
