import request from '../services/Api';
import { AxiosResponse } from 'axios';
import { GetComboActionPayloadTypes } from 'store/ProductCategories/types/getCombos';

export const fetchCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetSelfServeKioskCategories');

export const fetchActivitiesCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetSelfServeKioskCategories');

export const fetchFoodCategories = (): Promise<AxiosResponse> =>
  request.get('CoreAPI/Categories/GetFoodSelfServeKioskCategories');

export const fetchComboActivities = (data: GetComboActionPayloadTypes): Promise<AxiosResponse> =>
  request.post('CoreAPI/Categories/GetCombos', data);
