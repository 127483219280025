export interface Product {
  productId: number;
  productType: number;
  description: string;
  price1: number;
  price2: number;
  price3: number;
  price4: number;
  price5: number;
  taxId: number;
  productClassId: number;
  largeIcon: string;
  isSpecial: boolean;
  availableDay: string;
  availableFromTime: Date;
  availableToTime: Date;
  isRequiredMembership: boolean;
  showOnWeb: boolean;
  isTrackable: boolean;
  isShowStat: boolean;
  isInventory: boolean;
  cost: number;
  req: number;
  vendorId: number;
  enabled: boolean;
  deleted: boolean;
  p_PointTypeId: number;
  p_Points: number;
  bonusValue: number;
  paidValue: number;
  comValue: number;
  entitle1: number;
  entitle2: number;
  entitle3: number;
  entitle4: number;
  entitle5: number;
  entitle6: number;
  entitle7: number;
  entitle8: number;
  m_Points: number;
  m_MembershiptypeId: number;
  r_Points: number;
  r_LocalOnly: boolean;
  g_Points: number;
  s_SaleBy: number;
  s_Vol: number;
  s_NoOfLapsOrSeconds: number;
  s_CustId: number;
  priceCadet: number;
  dateCreated: Date;
  webShop: boolean;
  isAlcohol: boolean;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  sku: string;
  isCadet: boolean;
  showInSelfServeKiosk: boolean;
  highResImagePath: string;
}

export interface Member {
  custId: number | string;
  company: string;
  fName: string;
  lName: string;
  racerName: string;
  birthDate: string;
  ignoreDOB: boolean;
  gender: number;
  emailAddress: string;
  sourceId: number;
  hotel: string;
  industryId: number;
  refId: number;
  doNotMail: boolean;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumber: string;
  phoneNumber2: string;
  cell: string;
  fax: string;
  licenseNumber: string;
  issuedBy: string;
  waiver: number;
  waiver2: number;
  crdId: string;
  rpm: number;
  accountCreated: Date;
  lastVisited: Date;
  totalVisits: number;
  totalRaces: number;
  membershipStatus: number;
  membershipText: string;
  memberShipTextLong: string;
  priceLevel: number;
  promotionCode: string;
  isGiftCard: boolean;
  webUserName: string;
  password: string;
  award1: number;
  award2: number;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  privacy1: boolean;
  privacy2: boolean;
  privacy3: boolean;
  privacy4: boolean;
  status1: number;
  status2: number;
  status3: number;
  status4: number;
  deleted: boolean;
  isEmployee: boolean;
  originalId: number;
  creditLimit: number;
  creditOnHold: boolean;
  lastUnSubscribedDate: null;
  generalNotes: string;
  hash: null;
  consumingAlcohol: boolean;
  isRestricted: boolean;
  isGdprDeleted: boolean;
  pin: string;
  pinExists: boolean;
  pinError: string;
  loading: boolean;
  sessionId: null;
}

export interface ComboProduct {
  comboID: number;
  comboName: string;
  pack: number;
  contiguousNumberOfSlots: number;
  description: string;
  explanation: string;
  highResImagePath: string;
  isRequiredMembership: boolean;
  isSchedulable: boolean;
  price1: number;
  productID: number;
  quantity: number;
  isPerResource: boolean;
}

export enum ProductCategoriesScreenModes {
  FOOD = 'food',
  ACTIVITY = 'activity',
}

export interface IParams {
  categoryId?: string;
  comboId?: string;
}
