import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import blackCrossIcons from 'assets/icons/black-cross.svg';
import { removeItemFromCartAction } from 'store/Cart/actions';
import CartItem from '../CartItem';
import styles from './styles.module.scss';
import { CheckDetailsPerResource } from 'typings/cart';
import { Member } from 'typings/product';
import cn from 'classnames';
import Confirm from 'components/Prompt/Prompt';

interface CartItemProps {
  items: CheckDetailsPerResource;
  members: Member[];
  comboId?: string;
  activeComboGroupId?: number;
}

const ComboCartItemPerResource = ({ items, members, comboId, activeComboGroupId }: CartItemProps) => {
  const dispatch = useDispatch();

  const {
    comboProducts: { combos },
    cartStore: { comboCheckDetailsPerResource },
  } = useSelector(state => state);

  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const combInfo = combos.find(combo => combo.categoryID === items.comboId);

  const removeComboItem = () => {
    const guestsCustIds =
      comboCheckDetailsPerResource
        .find(combo => combo.comboId === items.comboId)
        ?.checkDetails?.map(item => item?.customerId) || [];

    dispatch(
      removeItemFromCartAction({
        isComboProduct: true,
        comboId: items.comboId,
        custIds: guestsCustIds,
        comboGroupId: items.comboGroupId,
        min: combInfo.minimumGuests,
        max: combInfo.maximumGuests,
      }),
    );
  };

  function disableRemoveCombosButton() {
    if (!comboId && !members?.length) {
      return false;
    }
    if (comboId === String(items.comboId) && activeComboGroupId === items.comboGroupId) {
      return true;
    }

    return false;
  }

  return (
    <div>
      <div className={styles.comboCartItemsHeader}>
        <h3 className={styles.comboName}>{combInfo?.description}</h3>
        <h3 className={styles.comboName}>${items?.comboPrice}</h3>
      </div>
      <div className={styles.comboCartItemsWrapper}>
        <div className={styles.comboCartItemsInfoWrapper}>
          {items.checkDetails.map((item, index) => (
            <CartItem
              key={`${item.checkDetailId} ${index}`}
              currentCartItem={item}
              checkDetailId={item.checkDetailId}
              productId={item.productId}
              productType={item.productType}
              hideRemoveButton={true}
              hideProductTitle={item.isSchedulable}
              hidePrice={true}
              isCombo={true}
              custId={Number(item?.customerId)}
            />
          ))}
        </div>
        <div>
          <button
            disabled={disableRemoveCombosButton()}
            className={cn(
              styles.cartItemsDelete,
              disableRemoveCombosButton() ? styles.cartItemsDeleteDisabled : styles.cartItemsDeleteActive,
            )}
            onClick={() => setShowWarningMessage(true)}
          >
            <img src={blackCrossIcons} alt="remove" />
          </button>
        </div>
      </div>
      <Confirm
        isOpen={showWarningMessage}
        title="Warning"
        titleButtonOk="OK"
        titleButtonCancel="Cancel"
        handleContinueClick={() => {
          setShowWarningMessage(false);
          removeComboItem();
        }}
        handleReturnClick={() => {
          setShowWarningMessage(false);
        }}
      >
        <div className={styles.guestLimitationsWarningModalWrapper}>
          <div>
            <p>Are you sure? This will remove the entire combo from your cart.</p>
          </div>
        </div>
      </Confirm>
    </div>
  );
};

export default ComboCartItemPerResource;
