import React, { useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { ROUTES } from '../../constants';
import { CARD_MONTH, CARD_YEAR } from '../../constants/paymentCard';
import { makePaymentCardConnectActionAction, resetCardConnectAction } from '../../store/Payment/actions';
import Modal from '../../components/Modal';
import IdleMonitor from '../../services/IdleMonitor';
import { history } from '../../App';
import ContentContainer from '../../components/ContentContainer';

import Button from '../../components/Button';
import warningIcon from '../../assets/icons/warningIcon.svg';

import styles from './styles.module.scss';
import HeaderPanel from '../../components/HeaderPanel';
import TextField from '../../components/TextField';
import Keyboard from '../../components/Keyboard/KeyboardHOC';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';
import Select from '../../components/Select';
import { formatCardNumber } from '../../utils/numberFormatter';
import fieldValidator from '../../utils/validator';
import { endCustomerSessionAction } from '../../store/General/actions';

const PaymentCardConnectScreen = () => {
  const dispatch = useDispatch();

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  const {
    terminalSettings: { cardConnectURL, isConvenienceFeeEnabled },
    paymentCardConnectStore: { errorMessage, attempts, loading: paymentCartConnectLoading },
    cartStore: { checkTotal, checkId, checkSubtotal, checkTax, checkClubSpeedKioskFeeTotal },
    payByLink: { paymentLinkId },
  } = useSelector(state => state);

  const [disableButton, setDisableButton] = useState(false);
  const [validationErrorAlert, setValidationErrorAlert] = React.useState(false);

  const [fieldsOfForm, setFieldsOfForm] = React.useState<any>({
    account: '',
    month: {},
    year: {},
    cvv2: '',
    postal: '',
  });
  const [activeField, setActiveField] = React.useState('account');

  const onPayHandler = async () => {
    setDisableButton(true);
    try {
      await fieldValidator('cvv2', fieldsOfForm.cvv2, true, false);
      await fieldValidator('postal', fieldsOfForm.postal, true, false);

      const response = await axios.post(`https://${cardConnectURL}/cardsecure/api/v1/ccn/tokenize`, {
        account: fieldsOfForm.account.replace(/[^\d]/g, ''),
        expiry: `${fieldsOfForm.month.value}${fieldsOfForm.year.value}`,
        cvv: fieldsOfForm.cvv2,
        postal: fieldsOfForm.postal,
      });

      dispatch(
        makePaymentCardConnectActionAction({
          checkId,
          token: response.data.token,
          cardExpirationDate: `${fieldsOfForm.month.value}${fieldsOfForm.year.value}`,
          cvv: fieldsOfForm.cvv2,
          postalCode: fieldsOfForm.postal,
          ...(paymentLinkId ? { paymentLinkId } : null),
        }),
      );
      setDisableButton(false);
    } catch (e) {
      setValidationErrorAlert(true);
      setDisableButton(false);
    }
  };

  const onPaymentOnlineHandler = () => {
    history.push(ROUTES.PAYMENT_ONLINE);
  };

  const onClickValidationError = event => {
    event.preventDefault();
    setValidationErrorAlert(false);
  };

  const onPaymentError = () => {
    dispatch(resetCardConnectAction());
    if (attempts === 3) {
      dispatch(endCustomerSessionAction());
    }
  };

  const onChangeInputValue = (e: any) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: e.target.value });
  };

  const onChangeInputCardValue = (e: any) => {
    setFieldsOfForm({ ...fieldsOfForm, [activeField]: formatCardNumber(e.target.value) });
  };

  const onKeyboardPress = (newFieldValue: string, fieldName) => {
    setFieldsOfForm({
      ...fieldsOfForm,
      [fieldName]: fieldName === 'account' ? formatCardNumber(newFieldValue) : newFieldValue,
    });
  };

  return (
    <>
      <ContentContainer theme="red" loading={paymentCartConnectLoading}>
        <HeaderPanel theme="dark" />
        <div className={styles.contentContainer}>
          <div className={styles.qwe}>
            <div className={styles.subtotal}>
              <span>
                Subtotal: <span className={styles.value}>${checkSubtotal.toFixed(2)}</span>
              </span>
            </div>
            <div className={styles.tax}>
              <span>
                {isConvenienceFeeEnabled ? 'Taxes & Fees:' : 'Tax:'}
                <span className={styles.value}>${(checkClubSpeedKioskFeeTotal + checkTax).toFixed(2)}</span>
              </span>
            </div>
          </div>
          <div className={styles.balance}>
            <span>
              Balance:{' '}
              <span className={styles.value}>${(checkTotal+checkClubSpeedKioskFeeTotal).toFixed(2)}</span>
            </span>
          </div>
          <h3 className={styles.description}>
            Enter your payment information below to
            <br /> complete your purchase
          </h3>
          <div className={styles.iframe}>
            <TextField
              type="text"
              readOnly={!nativeKeyboardState}
              value={fieldsOfForm.account}
              placeholder="Card number"
              isActive={activeField === 'account'}
              invalid={false}
              errorMessage={''}
              theme="themeTransparent"
              onChange={onChangeInputCardValue}
              onFocus={() => setActiveField('account')}
              customClass={styles.smallField}
            />

            <div className={styles.formSelect}>
              <div className={styles.formSelectWrap}>
                <Select
                  options={CARD_MONTH}
                  onChange={item => setFieldsOfForm({ ...fieldsOfForm, ['month']: item })}
                  value={fieldsOfForm.paymentProcessor}
                />
              </div>
              <div className={styles.formSelectWrap}>
                <Select
                  options={CARD_YEAR}
                  onChange={item => setFieldsOfForm({ ...fieldsOfForm, ['year']: item })}
                  value={fieldsOfForm.paymentProcessor}
                />
              </div>
            </div>

            <div className={styles.formWrapper}>
              <TextField
                type="password"
                readOnly={!nativeKeyboardState}
                value={fieldsOfForm.cvv2}
                placeholder="CVV"
                isActive={activeField === 'cvv2'}
                invalid={false}
                maxLength={4}
                errorMessage={''}
                theme="themeTransparent"
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('cvv2')}
                customClass={styles.smallField}
              />
              <TextField
                type="text"
                readOnly={!nativeKeyboardState}
                value={fieldsOfForm.postal}
                placeholder="Postal Code"
                isActive={activeField === 'postal'}
                invalid={false}
                errorMessage={''}
                theme="themeTransparent"
                onChange={onChangeInputValue}
                onFocus={() => setActiveField('postal')}
                customClass={styles.smallField}
              />
            </div>
          </div>
          {nativeKeyboardState && (
            <div className={styles.payButtonContainer}>
              <Button disabled={disableButton} handleClick={onPayHandler} theme="white" customClass={styles.payBtn}>
                Continue
              </Button>
            </div>
          )}
        </div>

        {nativeKeyboardState && (
          <div className={styles.footerContainer}>
            <Button handleClick={onPaymentOnlineHandler} theme="white" customClass={styles.paymentOnlineBtn}>
              Back
            </Button>
          </div>
        )}

        {!nativeKeyboardState && (
          <div className={styles.keyboard}>
            <Keyboard
              fieldName={activeField}
              valueOfField={fieldsOfForm[activeField]}
              onChange={onKeyboardPress}
              hideKeyboard={() => {}}
              handleEnter={() => {}}
              layout="cardNumber"
              maxLength={{
                cvv: 4,
              }}
            />
            <div className={styles.navigation}>
              <Button handleClick={onPaymentOnlineHandler} theme="white" customClass={styles.navigationText2payBtn}>
                Back
              </Button>
              <Button
                disabled={disableButton}
                handleClick={onPayHandler}
                theme="red"
                customClass={styles.navigationPayBtn}
              >
                Continue
              </Button>
            </div>
          </div>
        )}
      </ContentContainer>

      <Modal isOpen={validationErrorAlert}>
        <div className={styles.errorAlert}>
          <div className={styles.errorAlertContainer}>
            <img src={warningIcon} alt="" />
            <h2 className={styles.errorAlertTitle}>Validation error</h2>
            <p className={styles.errorAlertMessage}>Card data is invalid</p>
          </div>
          <div>
            <Button handleClick={onClickValidationError} theme="red" customClass={styles.errorAlertBtn}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={!!errorMessage}>
        <div className={styles.errorAlert}>
          <div className={styles.errorAlertContainer}>
            <img src={warningIcon} alt="" />
            <h2 className={styles.errorAlertTitle}>Payment Failed</h2>
            <p className={styles.errorAlertMessage}>{errorMessage}</p>
          </div>
          <div>
            <Button handleClick={onPaymentError} theme="red" customClass={styles.errorAlertBtn}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
      <IdleMonitor />
    </>
  );
};

export default PaymentCardConnectScreen;
