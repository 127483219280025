import { AxiosResponse } from 'axios';
import { IAddNonschedulableProductsToCart } from 'typings/cart';
import request from '../services/Api';

export const createCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks', data);
export const getCart = (cartId: number): Promise<AxiosResponse> => request.get(`CoreAPI/Checks/${cartId}`);

export const addToCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks/AddCheckDetails', data);

export const deleteFromCart = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/Checks/RemoveCheckDetails', data);

export const deleteCart = (data: any): Promise<AxiosResponse> => request.post('CoreAPI/Checks/RemoveCheck', data);

// Combos
export const addCombosToCart = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/Checks/AddCheckDetailsForCombo', data);

export const deleteComboFromCart = (data: any): Promise<AxiosResponse> =>
  request.post('CoreAPI/Checks/DropComboFromCarts', data);

export const addNonschedulableProductsToCart = (data: IAddNonschedulableProductsToCart): Promise<AxiosResponse> =>
  request.post('CoreAPI/Checks/AddComboNonSchedulableProductsInCheck', {
    checkId: data.checkId,
    productComboId: data.productComboId,
    products: data.products,
    customerIds: data.customerIds,
    isPerResource: data.isPerResource,
    comboGroupId: data.comboGroupId,
  });
