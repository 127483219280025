export enum APICredentalKeys {
  clientId = 'api_user_name',
  clientSecret = 'api_password',
  scope = 'api_server',
}

export interface IControlPanelSettingsItemResponse {
  createdDate: string;
  dataType: string;
  defaultSetting: string;
  description: string;
  fixed: string;
  settingName: string;
  settingValue: string;
  terminalName: string;
}
