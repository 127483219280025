export const ADD_ITEM_TO_CART_TYPES = {
  REQUEST: 'cart/ADD_ITEM_TO_CART_REQUEST',
  SUCCESS: 'cart/ADD_ITEM_TO_CART_SUCCESS',
  FAILURE: 'cart/ADD_ITEM_TO_CART_FAILURE',
  NONSCHEDULABLE: 'cart/ADD_NONSCHEDULABLE_PRODUCTS_TO_CART',
};

export const ADD_COMBO_ITEM_TO_CART_TYPES = {
  SUCCESS: 'cart/ADD_COMBO_ITEM_TO_CART_SUCCESS',
};

export const addItemToCartAction = (payload: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.REQUEST,
});

export const addItemToCartActionSuccess = (payload: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.SUCCESS,
});

export const addItemToCartActionFailure = (payload?: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.FAILURE,
});

export const addComboItemToCartActionSuccess = (payload: any) => ({
  payload,
  type: ADD_COMBO_ITEM_TO_CART_TYPES.SUCCESS,
});

export const addNonschedulableProductsToCart = (payload: any) => ({
  payload,
  type: ADD_ITEM_TO_CART_TYPES.NONSCHEDULABLE,
});
