import React, { useState, useEffect } from 'react';
import Keyboard from '../Keyboard';
import styles from './styles.module.scss';
import './selectSearch.scss';
import cn from 'classnames';
import Button from '../../components/Button';
import { useSelector } from 'react-redux';
import { nativeKeyboardStateSelector } from '../../store/Settings/selectors';

interface Props {
  value: string | number;
  valueField: string;
  displayField: string;
  fieldName: string;
  errorMessage?: string;
  source: any[];
  handleChange: (item: any, fieldName: any) => void;
  required?: boolean;
  children?: React.ReactNode;
  keyboardLayout?: string;
}

const WizardFormTypeSelector = ({
  value,
  fieldName,
  displayField,
  source,
  errorMessage,
  handleChange,
  required,
  children,
  keyboardLayout,
}: Props) => {
  const [localValue, setLocalValue] = useState(value);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const unify = item => {
    return item.target ? item.target : item;
  };

  useEffect(() => {
    if (!value) {
      setKeyboardState({ ...keyboardState, display: 'true' });
    }
  }, []);

  const nativeKeyboardState = useSelector(nativeKeyboardStateSelector);

  const onHandleChange = item => {
    const filteredSuggestions = source.filter(
      suggestion => suggestion.name.toLowerCase().indexOf(item.target.value.toLowerCase()) > -1,
    );
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(!!filteredSuggestions.length);
    setLocalValue(item.target.value);
  };

  const onChange = item => {
    handleChange(fieldName, item.value);
  };
  const [keyboardState, setKeyboardState] = useState({
    display: 'false',
  });
  const clear = async () => {
    setKeyboardState({ ...keyboardState, display: 'true' });
    setLocalValue('');
    setShowSuggestions(false);
    onChange('');
  };

  const onSuggestionClick = item => {
    setLocalValue(item.name);
    setShowSuggestions(false);
    onChange(unify(item));
  };

  const handleBackspace = () => {
    onChange('');
  };

  const onKeyboardPress = (newFieldValue: string) => {
    const filteredSuggestions = source.filter(
      suggestion => suggestion.name.toLowerCase().indexOf(newFieldValue.toLowerCase()) > -1,
    );
    setFilteredSuggestions(filteredSuggestions);
    setShowSuggestions(!!filteredSuggestions.length);
    setLocalValue(newFieldValue);
  };

  const suggestionsList = () => {
    if (filteredSuggestions.length) {
      return (
        <ul className={styles.showSuggestions}>
          {filteredSuggestions.slice(0, 3).map((suggestion, index) => {
            return (
              <li key={index} onClick={() => onSuggestionClick(suggestion)}>
                {suggestion.name}
              </li>
            );
          })}
        </ul>
      );
    }
    return <div />;
  };

  return (
    <div className={styles.WizardFormTypeSelector}>
      <div className={styles.WizardFormTypeSelectorInner}>
        <div>
          <div className={styles.titleWrap}>
            <h2 className={styles.title}>
              {keyboardState.display === 'true' && 'Please type your '}
              {displayField}
              {required && <span className={styles.required}>*</span>}
            </h2>
            {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
          </div>
          <div className={styles.titleWrap2}>
            <input
              type="text"
              onChange={onHandleChange}
              value={localValue}
              className={styles.tfInput}
              autoComplete="off"
              inputMode={nativeKeyboardState ? 'text' : 'none'}
              readOnly={keyboardState.display === 'false'}
            />
            <div className={styles.tfLine} />
            {showSuggestions && suggestionsList()}
            {children}
          </div>
        </div>
        {keyboardState.display === 'false' && (
          <Button theme="red" handleClick={clear} customClass={cn(styles.navigationButton)}>
            Clear
          </Button>
        )}
      </div>
      <div className={styles.keyboard}></div>
      <div className={styles.keyboard}>
        {!nativeKeyboardState && keyboardState.display === 'true' && (
          <Keyboard
            valueOfField={localValue}
            onChange={onKeyboardPress}
            hideKeyboard={() => {}}
            handleEnter={() => {}}
            mask={/^$|^[A-Z\sa-z]+$/}
            handleBackspace={handleBackspace}
            layout={keyboardLayout}
          />
        )}
      </div>
    </div>
  );
};

WizardFormTypeSelector.defaultProps = {
  label: '',
  placeholder: '',
  inputType: 'text',
  invalid: false,
  errorMessage: '',
  required: false,
  keyboardLayout: 'default',
  enterClick: () => {},
};

export default WizardFormTypeSelector;
