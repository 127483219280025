import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import styles from './styles.module.scss';
import ListModal from '../../ListModal';
interface CartSummaryProps {
  subtotal?: number;
  tax?: number;
  total?: number;
  fees?: number;
}

const CartSummary: React.FC<CartSummaryProps> = ({ subtotal, tax, fees, total }: CartSummaryProps) => {
  const { terminalSettings } = useSelector(state => state);

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.cartSummary}>
        <div className={styles.cartSummaryRow}>
          <div className={styles.cartSummaryTitle}>Subtotal</div>
          <div className={styles.cartSummaryPrice}>${subtotal.toFixed(2)}</div>
        </div>
        {terminalSettings.isConvenienceFeeEnabled ? (
          <div className={cn(styles.cartSummaryRow, styles.taxWrapper)}>
            <div className={styles.cartSummaryTitle} onClick={() => setShowModal(true)}>
              Taxes & Fees
            </div>
            <div className={styles.cartSummaryPrice}>${(fees + tax).toFixed(2)}</div>
          </div>
        ) : (
          <div className={cn(styles.cartSummaryRow)}>
            <div className={styles.cartSummaryTitle}>Tax</div>
            <div className={styles.cartSummaryPrice}>${tax.toFixed(2)}</div>
          </div>
        )}
        <div className={styles.cartSummaryRow}>
          <div className={cn(styles.cartSummaryTitle, styles.cartSummaryTitleTotal)}>Total</div>
          <div className={cn(styles.cartSummaryPrice, styles.cartSummaryPriceTotal)}>${total.toFixed(2)}</div>
        </div>
      </div>
      <ListModal
        zIndex={9999}
        isOpen={showModal}
        title="Taxes & Fees"
        list={{
          Taxes: `$ ${tax.toFixed(2)}`,
          Fees: `$ ${fees.toFixed(2)}`,
        }}
        handleClick={() => setShowModal(false)}
      />
    </>
  );
};

CartSummary.defaultProps = {
  subtotal: 0,
  tax: 0,
  total: 0,
};

export default CartSummary;
