import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivityGuestType } from '../../../store/Customer/types/assignActivities';
import isAdult from '../../../utils/isAdult';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import styles from './styles.module.scss';
import * as Api from '../../../api';
import SignatureCanvas from '../../SignatureCanvas';

const AddWaiverToGuest = ({
  customer,
  onFinish,
  closeWaiver,
  setWaiverAddPending,
}: {
  customer: ActivityGuestType;
  onFinish: () => void;
  closeWaiver: () => void;
  setWaiverAddPending: Dispatch<SetStateAction<boolean>>;
}) => {
  const sigPad = React.useRef(null);
  const [agreeWithRulesError, setAgreeWithRulesError] = useState(false);
  const [agreeWithRules, setAgreeWithRules] = useState(false);
  const [agreeWithESignitureRules, setAgreeWithESignitureRules] = useState(false);
  const [agreeWithESignitureRulesError, setAgreeWithESignitureRulesError] = useState(false);

  const {
    customerStore: primaryCustomer,
    registrationSettings: { adultAge, waiverStep },
    terminalSettings: { enableESignature },
  } = useSelector(state => state);

  const handleFinish = async () => {
    if (!agreeWithRules) {
      setAgreeWithRulesError(true);
    } else {
      if (enableESignature && !agreeWithESignitureRules) {
        setAgreeWithESignitureRulesError(true);
        return;
      }

      const signatureOfCustomer = sigPad.current.toDataURL().substr(22);
      setWaiverAddPending(true);
      if (isAdult(customer.birthDate, adultAge)) {
        try {
          await Api.createAdultWaiver({
            signature: signatureOfCustomer,
            adultCustId: customer.custId,
          });
          onFinish();
          setWaiverAddPending(false);
          setAgreeWithRules(false);
          setAgreeWithESignitureRules(false);
          sigPad.current.clear();
        } catch (error) {
          console.log(error);
          setWaiverAddPending(false);
        }
      } else {
        try {
          await Api.createMinorWaiver({
            guardianSignature: signatureOfCustomer,
            minorCustId: customer.custId,
            guardianCustId: primaryCustomer.custId,
          });
          setWaiverAddPending(false);
          setAgreeWithRules(false);
          setAgreeWithESignitureRules(false);
          onFinish();
          sigPad.current.clear();
        } catch (error) {
          console.log(error);
          setWaiverAddPending(false);
        }
      }
    }
  };

  return (
    <div>
      {isAdult(customer.birthDate, adultAge) ? (
        <>
          <div className={styles.contentWaiver}>
            <div className={styles.waiverAgreementTextContainer}>
              <span className={styles.waiverAgreementTextTitle}>
                Waiver Agreement - {customer.fName} {customer.lName}
              </span>
              <div className={styles.waiverAgreementTextText}>
                <div>{waiverStep.adultWaiverText}</div>
              </div>
            </div>
            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.iAgreeTextbox}>
                <Checkbox
                  state={!!agreeWithRules}
                  label={`I, ${customer.fName} ${customer.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                  handleClick={() => {
                    agreeWithRules && setAgreeWithESignitureRules(false);
                    setAgreeWithRules(prevState => !prevState);
                    setAgreeWithRulesError(false);
                  }}
                  error={agreeWithRulesError}
                />
              </div>
            </div>
            {enableESignature && (
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.iAgreeTextbox}>
                  <Checkbox
                    state={agreeWithESignitureRules}
                    label="By checking this checkbox, I agree that the e-signature below will be the electronic representation of my signature for all purposes, including legally binding contracts - just the same as a pen-and-paper signature."
                    handleClick={() => {
                      setAgreeWithESignitureRules(prevState => !prevState);
                      setAgreeWithESignitureRulesError(false);
                    }}
                    error={agreeWithESignitureRulesError}
                    disabled={!agreeWithRules}
                  />
                </div>
              </div>
            )}
            {agreeWithRules && (
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.waiverAgreementSignatureCanvas}>
                  <span className={styles.waiverAgreementSignatureTitle}>
                    <h2>
                      {agreeWithESignitureRules ? (
                        'Your E-signature'
                      ) : (
                        <>
                          {customer['fName']} {customer['lName']}, please sign below.
                        </>
                      )}
                    </h2>
                  </span>
                </div>
                <div className={styles.waiverAgreementSignatureCanvas}>
                  <div className={styles.waiverAgreementSignatureCanvasInner}>
                    <SignatureCanvas
                      canvasProps={{ width: 500, height: 200 }}
                      ref={sigPad}
                      sigPad={sigPad}
                      signature={`${customer['fName']} ${customer['lName']}`}
                      enableDigitalSignature={agreeWithESignitureRules}
                      readOnlySignature={enableESignature}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.contentWaiver}>
            <div className={styles.waiverAgreementTextContainer}>
              <span className={styles.waiverAgreementTextTitle}>
                <span className={styles.waiverAgreementTextTitle}>
                  {' '}
                  Waiver Agreement - {primaryCustomer.fName} {primaryCustomer.lName} (signing for {customer.fName}{' '}
                  {customer.lName})
                </span>
              </span>
              <div className={styles.waiverAgreementTextText}>
                <div>{waiverStep.minorWaiverText}</div>
              </div>
            </div>
            <div className={styles.waiverAgreementTextContainer}>
              <div className={styles.iAgreeTextbox}>
                <Checkbox
                  state={!!agreeWithRules}
                  label={`I, ${primaryCustomer.fName} ${primaryCustomer.lName}, agree to the terms and conditions of the above agreement and acknowledge that the signature below will apply to both my own waiver, and the waiver for any minors present in my group.`}
                  handleClick={() => {
                    agreeWithRules && setAgreeWithESignitureRules(false);
                    setAgreeWithRules(prevState => !prevState);
                    setAgreeWithRulesError(false);
                  }}
                  error={agreeWithRulesError}
                />
              </div>
            </div>
            {enableESignature && (
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.iAgreeTextbox}>
                  <Checkbox
                    state={agreeWithESignitureRules}
                    label="By checking this checkbox, I agree that the e-signature below will be the electronic representation of my signature for all purposes, including legally binding contracts - just the same as a pen-and-paper signature."
                    handleClick={() => {
                      setAgreeWithESignitureRules(prevState => !prevState);
                      setAgreeWithESignitureRulesError(false);
                    }}
                    error={agreeWithESignitureRulesError}
                    disabled={!agreeWithRules}
                  />
                </div>
              </div>
            )}
            {agreeWithRules && (
              <div className={styles.waiverAgreementTextContainer}>
                <div className={styles.waiverAgreementSignatureCanvas}>
                  <span className={styles.waiverAgreementSignatureTitle}>
                    <h2>
                      {agreeWithESignitureRules ? (
                        'Your E-signature'
                      ) : (
                        <>
                          {primaryCustomer.fName} {primaryCustomer.lName}, please sign below.
                        </>
                      )}
                    </h2>
                  </span>
                </div>
                <div className={styles.waiverAgreementSignatureCanvas}>
                  <div className={styles.waiverAgreementSignatureCanvasInner}>
                    <SignatureCanvas
                      canvasProps={{ width: 500, height: 200 }}
                      ref={sigPad}
                      sigPad={sigPad}
                      signature={`${primaryCustomer.fName} ${primaryCustomer.lName}`}
                      enableDigitalSignature={agreeWithESignitureRules}
                      readOnlySignature={enableESignature}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className={styles.navigation}>
        <Button theme="white" customClass={styles.navigationButton} handleClick={closeWaiver}>
          Back
        </Button>
        <div className={styles.navigationFilter} />
        <Button theme="red" customClass={styles.navigationButton} handleClick={handleFinish}>
          next
        </Button>
      </div>
    </div>
  );
};

export default AddWaiverToGuest;
