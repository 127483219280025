import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import updateTokenInterceptor from './interceptors/updateTokenInterceptor';

const { REACT_APP_KIOSK_URL,REACT_APP_TEST_CORE_API_URL } = process.env;

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

if (REACT_APP_KIOSK_URL) {
  instance.defaults.baseURL = REACT_APP_KIOSK_URL;
}

const refreshAuthLogic = async failedRequest => {
  try {
    const response = await instance.post('auth', {
      url:  REACT_APP_TEST_CORE_API_URL || `https://${localStorage.getItem('api_server')}.com/CoreAPI/`,
      username: `${localStorage.getItem('api_user_name')}`,
      password: `${localStorage.getItem('api_password')}`,
    });
    localStorage.setItem('token', response.data.token);
    failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.token}`;
    failedRequest.withCredentials = true;
  } catch (error) {
    throw new Error('Unauthorized');
  }
};

createAuthRefreshInterceptor(instance, refreshAuthLogic, {
  interceptNetworkError: true,
  pauseInstanceWhileRefreshing: true,
});

instance.interceptors.response.use(response => updateTokenInterceptor(response, instance), Promise.reject);

axios.interceptors.response.use(
  response => updateTokenInterceptor(response, instance),
  (error: any) => Promise.reject(error),
);

export default instance;
