import { ASSIGN_ACTIVITIES_TYPES } from '../actions';
import { AssignActivitiesInitialState } from '../types/assignActivities';

export const initialState: AssignActivitiesInitialState = {
  activities: null,
  selectedGuestsForActitivies: null,
};

export default function assignActivitiesReducer(
  state = initialState,
  { type, payload }: any,
): AssignActivitiesInitialState {
  switch (type) {
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        activities: {
          ...payload,
        },
      };
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_FAILURE:
      return {
        ...state,
        activities: {
          data: null,
        },
      };
    case ASSIGN_ACTIVITIES_TYPES.GET_ACTIVITIES_BY_CONFIRMATION_CODE_RESET:
      return {
        ...initialState,
      };
    case ASSIGN_ACTIVITIES_TYPES.CHECK_GUEST_REGISTRATION_IN_ACTIVITY_SUCCESS:
      return {
        ...state,
        selectedGuestsForActitivies: [...payload.data],
      };
    default:
      return state;
  }
}
