import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { GET_COMBOS_TYPES, getComboAction, getComboActionSuccess, getComboActionFailure } from '../actions';
import * as Api from '../../../api';
import { GetComboSagaPayload } from '../types/getCombos';

export function* getCombosSaga({ type, payload }: GetComboSagaPayload) {
  try {
    const { terminalSettings } = yield select();

    if (terminalSettings.enableCombos) {
      const { data: combos } = yield call(Api.fetchComboActivities, payload);

      yield put<any>(getComboActionSuccess({ combos }));
    }
  } catch (e) {
    yield put<any>(getComboActionFailure());
  }
}

export default function* combosSaga() {
  yield all([yield takeLatest(GET_COMBOS_TYPES.REQUEST, getCombosSaga)]);
}
