import React from 'react';
import styles from './styles.module.scss';

interface ProductGridItemProps {
  cover?: string;
  highResolutionCover?: string | null;
  title?: string;
  handleClick?: () => void;
  isRequiredMembership?: boolean;
  price?: any;
}

const ProductGridItem = ({
  isRequiredMembership,
  cover,
  highResolutionCover,
  title,
  handleClick,
  price,
}: ProductGridItemProps) => {
  return (
    <div className={styles.categoryItem} onClick={handleClick}>
      {price && (
        <div className={styles.categoryItemPrice}>${price?.price1?.toFixed(2) || price?.price?.toFixed(2)}</div>
      )}
      <div className={styles.categoryItemCover}>
        <img src={highResolutionCover ? highResolutionCover : `data:image/jpeg;base64,${cover}`} alt="" />
      </div>
      <div className={styles.categoryItemTitleContainer}>
        <h3 className={styles.categoryItemTitle}>{title}</h3>
      </div>
    </div>
  );
};

export default ProductGridItem;
