import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../App';
import { ROUTES } from '../../constants';
import { addItemToCartAction } from '../../store/Cart/actions';
import Modal from '../Modal';
import InputNumber from '../InputNumber';
import Button from '../Button';
import styles from './styles.module.scss';
import WizardFormField from '../WizardFormField';
import WizardFormMultiLineField from '../WizardFormMultiLineField';
import validator from 'validator';
import * as Api from '../../api';
import { getItemsApiCredentials } from 'utils/core';

interface Props {
  isOpen?: boolean;
  productDetail?: any;
  cancelHandler: () => void;
  setPromptsData?: any;
}

const QuickGiftCardProductView = ({ isOpen, productDetail, cancelHandler, setPromptsData }: Props) => {
  const dispatch = useDispatch();
  const {
    customerStore: { custId },
  } = useSelector(state => state);
  const [quantity, setQuantity] = useState(1);

  const changeQuantity = type => {
    if (type === 'plus') {
      setQuantity(prevState => prevState + 1);
    }
    if (type === 'minus' && quantity - 1 > 0) {
      setQuantity(prevState => prevState - 1);
    }
  };
  const [errorMessage, setErrorMessage] = useState('');

  const [onStepTwo, setOnStepTwo] = useState(false);

  const [fieldsOfForm, setFieldsOfForm] = useState<any>({
    quantity: '',
    emailAddress: '',
    note: '',
  });

  const closeQuickProductView = () => {
    if (onStepTwo) {
      setOnStepTwo(false);
      return;
    }

    setFieldsOfForm({ ...fieldsOfForm, ['emailAddress']: '', ['note']: '' });
    setQuantity(1);
    cancelHandler();
  };

  const onChange = (fieldName: string, newFieldValue: string) => {
    setFieldsOfForm({ ...fieldsOfForm, [fieldName]: newFieldValue });
  };

  const goToNextStep = async () => {
    if (!onStepTwo) {
      if (!validator.isEmail(fieldsOfForm['emailAddress'])) {
        setErrorMessage('Email is not valid');
        return;
      }
      setErrorMessage('');
      setOnStepTwo(true);
      return;
    }
    if (quantity <= 0) return;

    const {
      data: { prompts },
    } = await Api.fetchProductPrompts(productDetail.productId);

    const payload = {
      productType: productDetail.productType,
      isAddItemWithMembership: productDetail.isRequiredMembership,
      checkDetails: [
        {
          custId,
          scheduleTime: '',
          giftCardRecipientEmailAddress: fieldsOfForm['emailAddress'],
          giftCardNote: fieldsOfForm['note'],
          giftCardSender: '',
          productId: productDetail.productId,
          quantity: `${quantity}`,
        },
      ],
    };

    if (!prompts.length) {
      dispatch(addItemToCartAction({ ...payload }));
      setFieldsOfForm({ ...fieldsOfForm, ['emailAddress']: '', ['note']: '' });
      setErrorMessage('');
      closeQuickProductView();
      setQuantity(1);
      cancelHandler();
      history.replace(ROUTES.PRODUCTS);
    } else {
      await setPromptsData({ quantity, payload, promptData: prompts });
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} closeButtonHandler={closeQuickProductView}>
        <div className={styles.quickProductView}>
          {!onStepTwo && (
            <div className={styles.headerContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.productCover}>
                  {productDetail.highResImagePath && <img src={productDetail.highResImagePath} alt="" />}
                  {!productDetail.highResImagePath && (
                    <img src={`data:image/jpeg;base64,${productDetail.largeIcon}`} alt="" />
                  )}
                </div>
              </div>
              <div className={styles.centerContainer} />
              <div className={styles.rightContainer}>
                <div className={styles.mr20}>
                  {!onStepTwo && <InputNumber value={quantity} handleClick={changeQuantity} />}
                </div>
              </div>
            </div>
          )}
          {!onStepTwo && (
            <WizardFormField
              keyboardLayout="email"
              fieldName="emailAddress"
              value={fieldsOfForm['emailAddress']}
              label="Recipient Email"
              placeholder=""
              invalid={false}
              errorMessage={errorMessage}
              required={false}
              onChange={onChange}
              enterClick={goToNextStep}
            />
          )}
          {onStepTwo && (
            <WizardFormMultiLineField
              fieldName="note"
              value={fieldsOfForm['note']}
              label="Note"
              placeholder=""
              invalid={false}
              errorMessage={errorMessage}
              required={false}
              onChange={onChange}
              enterClick={goToNextStep}
            />
          )}

          <div className={styles.quickProductViewFooter}>
            <div className={styles.quickProductViewFooterLS}>
              <Button handleClick={closeQuickProductView} customClass={styles.whiteButton} theme="white">
                Back
              </Button>
            </div>
            <div className={styles.quickProductViewFooterRS}>
              <Button handleClick={goToNextStep} customClass={styles.redButton} theme="red">
                Continue
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

QuickGiftCardProductView.defaultProps = {
  isOpen: false,
};

export default QuickGiftCardProductView;
